<template>
    <div>
        <v-dialog
            v-model="openDialog"
            width="90vw"
            transition="dialog-bottom-transition"
            @click:outside="notifyClose"
            @keydown.esc="notifyClose"
        >
            <div
                style="background-color: white; border-radius: 16px; overflow: hidden; min-height: 300px"
                :style="windowWidth <= 900 ? 'max-height: 86vh' : 'max-height: 75vh'"
            >
                <div
                    style="background-color: #282f44; border-radius: 16px 16px 0 0"
                    class="pa-2 px-2 pl-4"
                >
                    <div style="width: 100%; height: 5vh; display: flex; justify-content: space-between; align-items: center">
                        <div :style="windowWidth <= 900 ? 'width: 10%' : 'width: 33%'">
                            <v-menu
                                v-if="windowWidth <= 900"
                                offset-y
                                style="background-color: white"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <img
                                            :src="optionsIcon"
                                            class="icon20 iconToWhite"
                                            alt="Optionen"
                                        >
                                    </v-btn>
                                </template>
                                <div style="background-color: white">
                                    <v-list>
                                        <v-list-item @click="setTypes('day'); setToday()">
                                            <v-list-item-title>Tag</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="setTypes('week'); setToday()">
                                            <v-list-item-title>Woche</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="setTypes('month'); setToday()">
                                            <v-list-item-title>Monat</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="setToday">
                                            <v-list-item-title>Zum heutigen Tag</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </v-menu>
                            <v-menu
                                v-if="windowWidth > 900"
                                bottom
                                right
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        outlined
                                        color="white"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <span>{{ typeToLabel[calendarType] }}</span>
                                                    <v-icon right>
                                                        mdi-menu-down
                                                    </v-icon>
                                                </div>
                                            </template>
                                            <span>Kalenderansicht auswählen</span>
                                        </v-tooltip>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="setTypes('day'); setToday()">
                                        <v-list-item-title>Tag</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="setTypes('week'); setToday()">
                                        <v-list-item-title>Woche</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="setTypes('month'); setToday()">
                                        <v-list-item-title>Monat</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        <div
                            style="display: flex; justify-content: center; align-items: center"
                            :style="windowWidth <= 900 ? 'width: 70%' : 'width: 33%'"
                        >
                            <ClickableBadge
                                v-if="showCalendarBadge"
                                :event-list="calendarBadgeEventList"
                                usage="calendar"
                                :delete-button="true"
                                @badgeClicked="calendarBadgeClick"
                                @clearBadges="clearBadges"
                            />
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        id="newEventMountMobile"
                                        icon
                                        color="white"
                                        :aria-label="`Vorherige${calendarType !== 'week' ? 'r' : ''} ${calendarType === 'day' ? 'Tag' : calendarType === 'week' ? 'Woche' : 'Monat'}`"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="previousPage"
                                    >
                                        <v-icon>mdi-chevron-left</v-icon>
                                    </v-btn>
                                </template>
                                <span v-if="calendarType === 'month'">vorheriger Monat</span>
                                <span v-else-if="calendarType === 'week'">vorherige Woche</span>
                                <span v-else>vorheriger Tag</span>
                            </v-tooltip>
                            <v-menu
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span
                                                    style="color: white; font-weight: 600; font-size: 1.4rem"
                                                    :style="windowWidth > 900 ? 'font-size: 1.4rem' : 'font-size: 1rem'"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    {{ calendarTitle }}
                                                </span>
                                            </template>
                                            <span v-if="calendarType === 'day'">Tag auswählen</span>
                                            <span v-else>Monat auswählen</span>
                                        </v-tooltip>
                                    </div>
                                </template>
                                <v-date-picker
                                    :value="focus"
                                    :type="focusDatePickerMode"
                                    min="2022-01-01"
                                    no-title
                                    locale="de-De"
                                    first-day-of-week="1"
                                    @change="changeFocus"
                                />
                            </v-menu>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        id="newEventMount"
                                        icon
                                        color="white"
                                        :aria-label="`Nächste${calendarType !== 'week' ? 'r' : ''} ${calendarType === 'day' ? 'Tag' : calendarType === 'week' ? 'Woche' : 'Monat'}`"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="nextPage"
                                    >
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </template>
                                <span v-if="calendarType === 'month'">nächster Monat</span>
                                <span v-else-if="calendarType === 'week'">nächste Woche</span>
                                <span v-else>nächster Tag</span>
                            </v-tooltip>
                        </div>
                        <div
                            style="display: flex; justify-content: flex-end; align-items: center"
                            :style="windowWidth <= 900 ? 'width: 20%' : 'width: 33%'"
                        >
                            <v-tooltip
                                v-if="windowWidth > 900"
                                bottom
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        outlined
                                        color="white"
                                        class="ma-2"
                                        aria-label="Zum heutigen Tag springen"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="setToday"
                                    >
                                        Heute
                                    </v-btn>
                                </template>
                                <span>zum heutigen Tag springen</span>
                            </v-tooltip>
                            <v-tooltip
                                bottom
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        text
                                        style="background-color: var(--v-chatColor-base)"
                                        :style="windowWidth <= 900 ? 'width: 28px; height: 28px' : ''"
                                        color="white"
                                        class="ma-2"
                                        :x-small="windowWidth <= 900"
                                        :disabled="showNewCalendarEvent"
                                        aria-label="Neues Ereignis"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="newCalendarEntry()"
                                    >
                                        <v-icon>
                                            mdi-plus
                                        </v-icon>
                                        <span v-if="windowWidth > 900">Ereignis</span>
                                    </v-btn>
                                </template>
                                <span>neues Ereignis erstellen</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="notifyClose"
                                    >
                                        <img
                                            :src="cancelIcon"
                                            class="icon20 iconToWhite"
                                            alt="schließen"
                                        >
                                    </v-btn>
                                </template>
                                <span>Kalender schließen</span>
                            </v-tooltip>
                        </div>
                    </div>
                </div>
                <div
                    style="padding: 0; margin: 0; overflow: hidden; min-height: 300px"
                    :style="windowWidth <= 900 ? 'max-height: 78vh' : 'max-height: 67vh'"
                    class="pa-2"
                >
                    <div style="height: 100%">
                        <div style="height: 100%; display: flex">
                            <div
                                style="width: 100%; height: 100%"
                            >
                                <!-- height of calender isn't final needs better implementation for mobile -->
                                <v-calendar
                                    ref="calendar"
                                    v-model="focus"
                                    locale="de-De"
                                    color="error"
                                    :type="calendarType"
                                    :events="events"
                                    :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                                    event-more-text="+{0} weitere"
                                    start="2022-01-01"
                                    show-week
                                    :style="windowWidth <= 900 ? 'height: 67vh' : 'height: 60vh'"
                                    class="calendarStyle"
                                    @click:more="viewDay"
                                    @click:date="viewDay"
                                    @click:event="viewEvent"
                                    @click:day="newCalendarEntryAt"
                                    @click:time="newCalendarEntryAtTime"
                                    @change="badgeClicked ? '' : calendarChange($event)"
                                />

                                <v-menu
                                    v-if="selectedOpen"
                                    v-model="selectedOpen"
                                    :close-on-content-click="false"
                                    :close-on-click="false"
                                    :activator="selectedElement"
                                    offset-x
                                    offset-overflow
                                    z-index="202"
                                    :min-width="windowWidth > 900 ? '' : '95vw'"
                                >
                                    <v-card
                                        :min-width="windowWidth > 900 ? '400px' : '95vw'"
                                        :max-width="windowWidth > 900 ? '400px' : '95vw'"
                                    >
                                        <v-card-title class="pa-2 px-2">
                                            <div style="width: 100%; display: flex; justify-content: space-between">
                                                <div
                                                    v-if="!disableEdit"
                                                    style="display: flex"
                                                    :style="showNewCalendarEvent ? 'width: 80%' : 'width: 60%'"
                                                >
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <Colorpicker
                                                                    :colors="selectableColors"
                                                                    :previous-color="tempColor"
                                                                    @selected="(selectedColor) => {tempColor = selectedColor;}"
                                                                />
                                                            </div>
                                                        </template>
                                                        <span>Ereignisfarbe auswählen</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <v-text-field
                                                                    ref="titleTextfield"
                                                                    v-model="tempName"
                                                                    placeholder="Ereignis eintragen *"
                                                                    dense
                                                                    hide-details
                                                                    :color="tempColor"
                                                                    class="pl-2 pr-2"
                                                                    style="margin: 0; font-size: larger"
                                                                >
                                                                    <template #append>
                                                                        <div style="align-self: center">
                                                                            <img
                                                                                :src="editIcon"
                                                                                style="height: 18px"
                                                                                alt="bearbeiten"
                                                                                class="iconToDarkBlue"
                                                                            >
                                                                        </div>
                                                                    </template>
                                                                </v-text-field>
                                                            </div>
                                                        </template>
                                                        <span>Ereignistitel eingeben</span>
                                                    </v-tooltip>
                                                </div>
                                                <div
                                                    v-else
                                                    style="display: flex; flex-flow: row; width: 90%; align-self: center"
                                                >
                                                    <span
                                                        style=" display: block; font-size: 2rem; word-break: break-word; white-space: normal"
                                                        :style="`color: ${tempColor}`"
                                                        class="ml-2"
                                                    >{{ tempName }}</span>
                                                    <!-- FAB read text button for kids -->
                                                    <v-tooltip
                                                        :disabled="!showTooltips"
                                                        bottom
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                v-if="selectedOpen && disableEdit && (accountRole === 'pupil' || accountRole === 'parent')"
                                                                :aria-hidden="me && me.accessibility && me.accessibility.screenreader"
                                                                icon
                                                                small
                                                                class="pa-0 ml-2 preventColorInvert"
                                                                style="height: 32px; background-color:white; border-radius: 4px !important;"
                                                                v-on="on"
                                                                @click="readOutCalendarEntry"
                                                            >
                                                                <img
                                                                    :src="usedSpeakerIcon"
                                                                    class="icon20"
                                                                    alt="Vorlesen"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Vorlesen</span>
                                                    </v-tooltip>
                                                </div>
                                                <div
                                                    v-if="!disableEdit"
                                                    style="display: flex; justify-content: flex-end"
                                                    :style="showNewCalendarEvent ? 'width: 20%' : 'width: 40%'"
                                                >
                                                    <v-menu
                                                        offset-y
                                                        nudge-left="75"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                v-if="!showNewCalendarEvent"
                                                                icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <img
                                                                            :src="exportIcon"
                                                                            style="height: 20px;"
                                                                            class="mx-2 iconToDarkBlue"
                                                                            alt="Exportieren"
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        >
                                                                    </template>
                                                                    <span>Ereignis teilen</span>
                                                                </v-tooltip>
                                                            </v-btn>
                                                        </template>
                                                        <v-list style="padding: 0">
                                                            <v-list-item style="padding: 0">
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <div
                                                                            style="width: 100%; display: flex; justify-content: flex-start; align-items: center"
                                                                            class="pl-4 pr-4 clickable"
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            @click="createICal"
                                                                        >
                                                                            <img
                                                                                :src="calendarIcon"
                                                                                style="height: 20px; padding: 0; margin-left: 0 !important;"
                                                                                class="mx-2 iconToDarkBlue"
                                                                                alt="iCal-Downlaod"
                                                                            >
                                                                            <span
                                                                                class="pl-2"
                                                                            >
                                                                                iCal downloaden
                                                                            </span>
                                                                        </div>
                                                                    </template>
                                                                    <span>Datei für Import in externe Kalender generieren</span>
                                                                </v-tooltip>
                                                            </v-list-item>
                                                            <v-list-item style="padding: 0">
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <div
                                                                            style="width: 100%; display: flex; justify-content: flex-start; align-items: center"
                                                                            class="pl-4"
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            @click="showEmailDialog = true"
                                                                        >
                                                                            <v-icon
                                                                                color="#282f44"
                                                                            >
                                                                                mdi-email
                                                                            </v-icon>
                                                                            <span
                                                                                class="pl-2 clickable"
                                                                            >
                                                                                Per Email teilen
                                                                            </span>
                                                                        </div>
                                                                    </template>
                                                                    <span>Ereignis per Email teilen</span>
                                                                </v-tooltip>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click="checkSaveClick"
                                                            >
                                                                <img
                                                                    :src="saveIcon"
                                                                    class="icon20 iconToDarkBlue"
                                                                    alt="speichern"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Ereignis speichern</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                v-if="!showNewCalendarEvent"
                                                                icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click="tempRepeat === 'nicht wiederholen' ? showDeleteDialog = true : showDeleteRepeatDialog = true"
                                                            >
                                                                <img
                                                                    :src="deleteIcon"
                                                                    class="icon20 iconToRed"
                                                                    alt="löschen"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Ereignis löschen</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click="cancelClick"
                                                            >
                                                                <img
                                                                    :src="cancelIcon"
                                                                    class="icon20"
                                                                    alt="schließen"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Ereignis schließen</span>
                                                    </v-tooltip>
                                                </div>
                                                <div
                                                    v-else
                                                    style="width: 10%; display: flex; justify-content: flex-end; align-items: center"
                                                >
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click="createICal"
                                                            >
                                                                <img
                                                                    :src="exportIcon"
                                                                    style="height: 20px;"
                                                                    class="mx-2 iconToDarkBlue"
                                                                    alt="exportieren"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Datei für Import in externe Kalender generieren</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click="cancelClick"
                                                            >
                                                                <img
                                                                    :src="cancelIcon"
                                                                    class="icon20 iconToDarkBlue"
                                                                    alt="schließen"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Ereignis schließen</span>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                        </v-card-title>
                                        <v-card-text style="width: 100%">
                                            <div
                                                v-if="!disableEdit"
                                                class="mt-4"
                                            >
                                                <div
                                                    style="display: flex; align-items: center"
                                                >
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div
                                                                class="mr-2"
                                                                style="width: 50%"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <v-menu
                                                                    ref="startDateMenu"
                                                                    v-model="startDateMenu"
                                                                    :close-on-content-click="false"
                                                                    :return-value.sync="tempStartDate"
                                                                    transition="scale-transition"
                                                                    offset-y
                                                                    offset-overflow
                                                                    min-width="auto"
                                                                >
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-text-field
                                                                            v-model="tempStartDate"
                                                                            readonly
                                                                            dense
                                                                            style="padding: 0; margin: 0"
                                                                            hide-details
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        >
                                                                            <img
                                                                                slot="prepend"
                                                                                :src="calendarIcon"
                                                                                style="height: 20px; padding: 0; margin-left: 0 !important;"
                                                                                class="mx-2 iconToDarkBlue"
                                                                                alt="Kalendar"
                                                                            >
                                                                        </v-text-field>
                                                                    </template>
                                                                    <v-date-picker
                                                                        v-model="tempStartDate"
                                                                        no-title
                                                                        scrollable
                                                                        color="#282f44"
                                                                        :first-day-of-week="1"
                                                                        @change="$refs.startDateMenu.save(tempStartDate)"
                                                                    />
                                                                </v-menu>
                                                            </div>
                                                        </template>
                                                        <span>Startdatum auswählen</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div
                                                                style="width: 50%; display: flex"
                                                                class="ml-2"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <v-menu
                                                                    ref="startTimeMenu"
                                                                    v-model="startTimeMenu"
                                                                    :close-on-content-click="false"
                                                                    :return-value.sync="tempStartTime"
                                                                    transition="scale-transition"
                                                                    offset-x
                                                                    offset-overflow
                                                                    max-width="290px"
                                                                    min-width="290px"
                                                                >
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-text-field
                                                                            v-model="tempStartTime"
                                                                            readonly
                                                                            hide-details
                                                                            dense
                                                                            :disabled="tempTimed"
                                                                            style="margin: 0; padding: 0"
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        >
                                                                            <template #prepend>
                                                                                <img
                                                                                    :src="clockIcon"
                                                                                    style="height: 20px;"
                                                                                    class="mr-2"
                                                                                    :class="tempTimed ? 'iconToDunkelgrau' : 'iconToDarkBlue'"
                                                                                    alt="Start-Zeit"
                                                                                >
                                                                            </template>
                                                                        </v-text-field>
                                                                    </template>
                                                                    <v-time-picker
                                                                        v-if="startTimeMenu"
                                                                        v-model="tempStartTime"
                                                                        format="24hr"
                                                                        full-width
                                                                        color="#282f44"
                                                                        @click:minute="$refs.startTimeMenu.save(tempStartTime)"
                                                                    />
                                                                </v-menu>
                                                            </div>
                                                        </template>
                                                        <span>Startzeit wählen</span>
                                                    </v-tooltip>
                                                </div>
                                                <div
                                                    style="display: flex; align-items: center"
                                                >
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div
                                                                class="mr-2"
                                                                style="width: 50%"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <v-menu
                                                                    ref="endDateMenu"
                                                                    v-model="endDateMenu"
                                                                    :close-on-content-click="false"
                                                                    :return-value.sync="tempEndDate"
                                                                    transition="scale-transition"
                                                                    offset-y
                                                                    min-width="auto"
                                                                >
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-text-field
                                                                            v-model="tempEndDate"
                                                                            readonly
                                                                            dense
                                                                            style="padding: 0; margin: 0"
                                                                            hide-details
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        >
                                                                            <img
                                                                                slot="prepend"
                                                                                :src="calendarIcon"
                                                                                style="height: 20px; padding: 0; margin-left: 0 !important;"
                                                                                class="mx-2 iconToDarkBlue"
                                                                                alt="Kalender"
                                                                            >
                                                                        </v-text-field>
                                                                    </template>
                                                                    <v-date-picker
                                                                        v-model="tempEndDate"
                                                                        :first-day-of-week="1"
                                                                        no-title
                                                                        scrollable
                                                                        locale="de-De"
                                                                        color="#282f44"
                                                                        :min="tempStartDate"
                                                                        @change="$refs.endDateMenu.save(tempEndDate)"
                                                                    />
                                                                </v-menu>
                                                            </div>
                                                        </template>
                                                        <span>Enddatum auswählen</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div
                                                                style="width: 50%; display: flex"
                                                                class="ml-2"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <v-menu
                                                                    ref="endTimeMenu"
                                                                    v-model="endTimeMenu"
                                                                    :close-on-content-click="false"
                                                                    :return-value.sync="tempEndTime"
                                                                    transition="scale-transition"
                                                                    offset-x
                                                                    offset-overflow
                                                                    max-width="290px"
                                                                    min-width="290px"
                                                                >
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-text-field
                                                                            v-model="tempEndTime"
                                                                            readonly
                                                                            hide-details
                                                                            dense
                                                                            :disabled="tempTimed"
                                                                            style="margin: 0"
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        >
                                                                            <template #prepend>
                                                                                <img
                                                                                    :src="clockIcon"
                                                                                    style="height: 20px;"
                                                                                    class="mr-2"
                                                                                    :class="tempTimed ? 'iconToDunkelgrau' : 'iconToDarkBlue'"
                                                                                    alt="Ende"
                                                                                >
                                                                            </template>
                                                                        </v-text-field>
                                                                    </template>
                                                                    <v-time-picker
                                                                        v-if="endTimeMenu"
                                                                        v-model="tempEndTime"
                                                                        format="24hr"
                                                                        full-width
                                                                        color="#282f44"
                                                                        @click:minute="$refs.endTimeMenu.save(tempEndTime)"
                                                                    />
                                                                </v-menu>
                                                            </div>
                                                        </template>
                                                        <span>Endzeit auswählen</span>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div v-if="tempTimed">
                                                    <div
                                                        v-if="tempStartDate === tempEndDate"
                                                        style="display: flex"
                                                    >
                                                        <img
                                                            :src="calendarIcon"
                                                            style="height: 20px; padding: 0;"
                                                            class="mr-1 iconToDarkBlue"
                                                            alt="Kalender"
                                                        >
                                                        <span
                                                            class="pl-2"
                                                            style="font-size: 1rem; color: black"
                                                        >
                                                            {{ tempStartDateString }}
                                                        </span>
                                                        <v-btn
                                                            v-if="createBBB"
                                                            color="#009600"
                                                            class="ml-4"
                                                            small
                                                            @click="joinBBBGuest"
                                                        >
                                                            <img
                                                                v-if="createBBB"
                                                                :src="videoIcon"
                                                                style="height: 20px;"
                                                                class="mr-1 iconToWhite clickable"
                                                                alt="Video"
                                                            >
                                                            <span style="color: white">Beitreten</span>
                                                        </v-btn>
                                                        <img
                                                            v-if="createRoom"
                                                            :src="roomIcon"
                                                            style="height: 20px;"
                                                            class="mx-2 iconToDarkBlue"
                                                            alt="Raum"
                                                        >
                                                        <span
                                                            v-if="createRoom"
                                                            style="font-size: 1rem; color: black"
                                                        >
                                                            {{ tempRoomName }}
                                                        </span>
                                                    </div>
                                                    <div
                                                        v-else
                                                        style="display: flex"
                                                    >
                                                        <img
                                                            :src="calendarIcon"
                                                            style="height: 20px; padding: 0;"
                                                            class="mr-1 iconToDarkBlue"
                                                            alt="'Kalender'"
                                                        >
                                                        <span
                                                            class="pl-2"
                                                            style="font-size: 1rem; color: black"
                                                        >
                                                            {{ tempStartDateString }} -
                                                        </span>
                                                        <span style="font-size: 1rem; color: black">
                                                            {{ tempEndDateString }}
                                                        </span>
                                                        <v-btn
                                                            v-if="createBBB"
                                                            color="#009600"
                                                            class="ml-4"
                                                            small
                                                            @click="joinBBBGuest"
                                                        >
                                                            <img
                                                                v-if="createBBB"
                                                                :src="videoIcon"
                                                                style="height: 20px;"
                                                                class="mr-1 iconToWhite clickable"
                                                                alt="BBB"
                                                            >
                                                            <span style="color: white">Beitreten</span>
                                                        </v-btn>
                                                        <img
                                                            v-if="createRoom"
                                                            :src="roomIcon"
                                                            style="height: 20px;"
                                                            class="mx-2 iconToDarkBlue"
                                                            alt="Raum"
                                                        >
                                                        <span
                                                            v-if="createRoom"
                                                            style="font-size: 1rem; color: black"
                                                        >
                                                            {{ tempRoomName }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    v-else
                                                    style="display: flex"
                                                >
                                                    <div
                                                        v-if="tempStartDate === tempEndDate"
                                                    >
                                                        <div style="display: flex; align-items:center">
                                                            <img
                                                                :src="calendarIcon"
                                                                style="height: 20px; padding: 0;"
                                                                class="mr-1 iconToDarkBlue"
                                                                alt="Kalender"
                                                            >
                                                            <span
                                                                class="pl-2"
                                                                style="font-size: 1rem; color: black"
                                                            >
                                                                {{ tempStartDateString }}
                                                            </span>
                                                            <img
                                                                :src="clockIcon"
                                                                style="height: 20px;"
                                                                class="iconToDarkBlue pl-4"
                                                                alt="Uhrzeit"
                                                            >
                                                            <span
                                                                style="font-size: 1rem; color: black"
                                                                class="pl-2"
                                                            >
                                                                {{ tempStartTime }} Uhr - {{ tempEndTime }} Uhr
                                                            </span>
                                                        </div>
                                                        <div
                                                            style="display: flex"
                                                            class="mt-2"
                                                        >
                                                            <v-btn
                                                                v-if="createBBB"
                                                                color="#009600"
                                                                class="ml-4"
                                                                small
                                                                @click="joinBBBGuest"
                                                            >
                                                                <img
                                                                    v-if="createBBB"
                                                                    :src="videoIcon"
                                                                    style="height: 20px;"
                                                                    class="mr-1 iconToWhite clickable"
                                                                    alt="BigBlueButton"
                                                                >
                                                                <span style="color: white">Beitreten</span>
                                                            </v-btn>
                                                            <img
                                                                v-if="createRoom"
                                                                :src="roomIcon"
                                                                style="height: 20px;"
                                                                class="mr-1 iconToDarkBlue"
                                                                alt="Raum"
                                                            >
                                                            <span
                                                                v-if="createRoom"
                                                                style="font-size: 1rem; color: black"
                                                                class="pl-2"
                                                            >
                                                                {{ tempRoomName }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-else
                                                    >
                                                        <div style="display: flex">
                                                            <img
                                                                :src="calendarIcon"
                                                                style="height: 20px; padding: 0;"
                                                                class="mr-2 iconToDarkBlue"
                                                                alt="Kalender"
                                                            >
                                                            <span
                                                                class="pl-2"
                                                                style="font-size: 1rem; color: black"
                                                            >
                                                                {{ tempStartDateString }}
                                                            </span>
                                                            <img
                                                                :src="clockIcon"
                                                                style="height: 20px;"
                                                                class="iconToDarkBlue pl-4"
                                                                alt="clock-Icon"
                                                            >
                                                            <span
                                                                style="font-size: 1rem; color: black"
                                                                class="pl-2"
                                                            >
                                                                {{ tempStartTime }} Uhr
                                                            </span>
                                                        </div>
                                                        <div style="display: flex">
                                                            <img
                                                                :src="calendarIcon"
                                                                style="height: 20px; padding: 0;"
                                                                class="mr-2 iconToDarkBlue"
                                                                alt="Kalender"
                                                            >
                                                            <span
                                                                class="pl-2"
                                                                style="font-size: 1rem; color: black"
                                                            >
                                                                {{ tempEndDateString }}
                                                            </span>
                                                            <img
                                                                :src="clockIcon"
                                                                style="height: 20px;"
                                                                class="iconToDarkBlue pl-4"
                                                                alt="Ende"
                                                            >
                                                            <span
                                                                style="font-size: 1rem; color: black"
                                                                class="pl-2"
                                                            >
                                                                {{ tempEndTime }} Uhr
                                                            </span>
                                                            <v-btn
                                                                v-if="createBBB"
                                                                color="#009600"
                                                                class="ml-4"
                                                                small
                                                                @click="joinBBBGuest"
                                                            >
                                                                <img
                                                                    v-if="createBBB"
                                                                    :src="videoIcon"
                                                                    style="height: 20px;"
                                                                    class="mr-1 iconToWhite clickable"
                                                                    alt="BigBlueButton"
                                                                >
                                                                <span style="color: white">Beitreten</span>
                                                            </v-btn>
                                                            <img
                                                                v-if="createRoom"
                                                                :src="roomIcon"
                                                                style="height: 20px;"
                                                                class="mr-1 iconToDarkBlue"
                                                                alt="Room-Icon"
                                                            >
                                                            <span
                                                                v-if="createRoom"
                                                                style="font-size: 1rem; color: black"
                                                                class="pl-2"
                                                            >
                                                                {{ tempRoomName }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-if="!disableEdit"
                                                class="mt-2"
                                            >
                                                <tr style="width: 100%; display: flex; align-items: flex-start">
                                                    <td style="width: 40%">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <div
                                                                    style="display: flex; align-items: center"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >
                                                                    <v-checkbox
                                                                        v-model="tempTimed"
                                                                        hide-details
                                                                        color="#282f44"
                                                                        style="margin: 0 10px 0 0"
                                                                        off-icon="mdi-checkbox-blank-circle-outline"
                                                                        on-icon="mdi-checkbox-marked-circle-outline"
                                                                    />
                                                                    <span style="font-size: large; color: black">ganztägig</span>
                                                                </div>
                                                            </template>
                                                            <span>Ganztägige Events zeigen keine Uhrzeit an</span>
                                                        </v-tooltip>
                                                    </td>
                                                    <td style="width: 60%">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <div
                                                                    style="display: flex; align-items: center"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                >
                                                                    <v-select
                                                                        v-model="tempRepeat"
                                                                        :items="['nicht wiederholen', 'wöchentlich', 'monatlich']"
                                                                        dense
                                                                        hide-details
                                                                        style="margin: 0; padding: 0"
                                                                    >
                                                                        <template #prepend>
                                                                            <div>
                                                                                <v-icon
                                                                                    v-if="tempRepeat === 'nicht wiederholen'"
                                                                                    style="height: 18px"
                                                                                    alt="nicht wiederholen"
                                                                                >
                                                                                    mdi-checkbox-blank-circle-outline
                                                                                </v-icon>
                                                                                <v-icon
                                                                                    v-else
                                                                                    style="height: 18px"
                                                                                    alt="wiederholen"
                                                                                    color="#282f44"
                                                                                >
                                                                                    mdi-checkbox-marked-circle-outline
                                                                                </v-icon>
                                                                            </div>
                                                                        </template>
                                                                    </v-select>
                                                                </div>
                                                            </template>
                                                            <span>Wiederholung auswählen</span>
                                                        </v-tooltip>
                                                    </td>
                                                </tr>
                                                <div style="width: 100%; display: flex; align-items: center">
                                                    <div
                                                        v-if="currentAccount.role === 'teacher' || currentAccount.role === 'maintainer'"
                                                        style="width: 55%"
                                                        class="pt-2"
                                                    >
                                                        <div
                                                            style="display: flex; align-items: center"
                                                        >
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <div
                                                                        style="height: 36px; display: flex; align-items: center"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        <v-checkbox
                                                                            v-model="addRoom"
                                                                            hide-details
                                                                            color="#282f44"
                                                                            style="margin: 0 10px 0 0; padding: 0"
                                                                            off-icon="mdi-checkbox-blank-circle-outline"
                                                                            on-icon="mdi-checkbox-marked-circle-outline"
                                                                        />
                                                                        <span style="font-size: large; color: black">Raum</span>
                                                                    </div>
                                                                </template>
                                                                <span>Raum oder Video Raum hinzufügen</span>
                                                            </v-tooltip>
                                                            <div
                                                                v-if="addRoom"
                                                                style="display: flex; align-items: center"
                                                            >
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            icon
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            @click="addRoomClick"
                                                                        >
                                                                            <img
                                                                                :src="roomIcon"
                                                                                style="height: 20px;"
                                                                                :class="createRoom ? 'iconToDarkBlue' : 'iconToDunkelgrau'"
                                                                                alt="Raum"
                                                                            >
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Raum hinzufügen</span>
                                                                </v-tooltip>
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            icon
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            @click="addBBBClick"
                                                                        >
                                                                            <img
                                                                                :src="createBBB ? videoIcon : videoOffIcon"
                                                                                style="height: 20px;"
                                                                                :class="createBBB ? 'iconToDarkBlue' : 'iconToDunkelgrau'"
                                                                                alt="BigBlueButton"
                                                                            >
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Videoraum hinzufügen</span>
                                                                </v-tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div
                                                                style="width: 45%"
                                                                class="pt-2"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <v-menu
                                                                    ref="repeatUntilMenu"
                                                                    :close-on-content-click="false"
                                                                    :return-value.sync="tempRepeatUntil"
                                                                    transition="scale-transition"
                                                                    offset-y
                                                                    left
                                                                    :disabled="tempRepeat === 'nicht wiederholen'"
                                                                    min-width="auto"
                                                                >
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-text-field
                                                                            ref="repeatUntilTextfield"
                                                                            v-model="tempRepeatUntil"
                                                                            placeholder="wiederholen bis"
                                                                            readonly
                                                                            dense
                                                                            style="padding: 0; margin: 0"
                                                                            :disabled="tempRepeat === 'nicht wiederholen'"
                                                                            hide-details
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        >
                                                                            <img
                                                                                slot="prepend"
                                                                                :src="calendarIcon"
                                                                                style="height: 20px; padding: 0;"
                                                                                class="mr-1"
                                                                                :class="tempRepeat === 'nicht wiederholen' ? 'iconToDunkelgrau' : 'iconToDarkBlue'"
                                                                                alt="Kalender"
                                                                            >
                                                                        </v-text-field>
                                                                    </template>
                                                                    <v-date-picker
                                                                        v-model="tempRepeatUntil"
                                                                        no-title
                                                                        scrollable
                                                                        :first-day-of-week="1"
                                                                        locale="de-De"
                                                                        color="#7bb337"
                                                                        :min="tempEndDate"
                                                                        @change="$refs.repeatUntilMenu.save(tempRepeatUntil)"
                                                                    />
                                                                </v-menu>
                                                            </div>
                                                        </template>
                                                        <span>Ende der Wiederholung wählen</span>
                                                    </v-tooltip>
                                                </div>
                                                <div
                                                    style="width: 75%; display: flex; align-items: center"
                                                    class="mt-2 mb-2"
                                                >
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div
                                                                style="display: flex; align-items: center"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <v-select
                                                                    v-model="tempRemind"
                                                                    :items="['keine Erinnerung', 'zum Zeitpunkt des Events', '1 Stunde davor', '1 Tag davor', '2 Tage davor', '1 Woche davor']"
                                                                    dense
                                                                    hide-details
                                                                    style="margin: 0; padding: 0"
                                                                >
                                                                    <template #prepend>
                                                                        <div>
                                                                            <img
                                                                                :src="enableReminder ? reminderOnIcon : reminderOffIcon"
                                                                                style="height: 20px; padding: 0;"
                                                                                class="mr-1"
                                                                                :class="enableReminder ? 'iconToDarkBlue' : 'iconToDunkelgrau'"
                                                                                alt="Erinnerung"
                                                                            >
                                                                        </div>
                                                                    </template>
                                                                </v-select>
                                                            </div>
                                                        </template>
                                                        <span>Erinnerung aktivieren</span>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                            <div style="display: flex; justify-content: space-between; align-items: center">
                                                <div
                                                    v-if="disableEdit && tempTimed"
                                                    class="mt-1"
                                                >
                                                    <div style="display: flex; align-items: center">
                                                        <v-icon color="#282f44">
                                                            mdi-checkbox-marked-circle-outline
                                                        </v-icon>
                                                        <span
                                                            style="font-size: 1rem; color: black"
                                                            class="ml-2"
                                                        >ganztägig</span>
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="disableEdit && tempRepeat !== 'nicht wiederholen'"
                                                    style="display: flex"
                                                >
                                                    <img
                                                        :src="calendarRepeatIcon"
                                                        style="height: 20px;"
                                                        class="pr-2 iconToDarkBlue"
                                                        alt="Kalender"
                                                    >
                                                    <span
                                                        style="font-size: 1rem; color: black"
                                                    >
                                                        {{ tempRepeat }}
                                                    </span>
                                                </div>
                                            </div>
                                            <v-menu
                                                v-if="!disableEdit && (currentAccount.role === 'teacher' || currentAccount.role === 'maintainer')"
                                                v-model="showTreeView"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                offset-overflow
                                                min-width="300px"
                                                max-width="auto"
                                                max-height="250px"
                                                style="overflow-y: scroll"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        readonly
                                                        dense
                                                        style="padding: 0; margin: 0"
                                                        hide-details
                                                        :placeholder="selectedUsers.length === 0 ? 'Teilen mit' : selectedUsers.length + ' Nutzer ausgewählt'"
                                                        class="mt-2"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        <img
                                                            slot="prepend"
                                                            :src="multipleUsersIcon"
                                                            style="height: 20px;"
                                                            class="mr-2 iconToDarkBlue"
                                                            alt="NutzerIcon"
                                                        >
                                                        <template #append>
                                                            <div style="display: flex; align-items: center">
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <div
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        >
                                                                            <v-checkbox
                                                                                v-model="tempAccountsViewable"
                                                                                hide-details
                                                                                dense
                                                                                color="#282f44"
                                                                                style="margin: 0 8px 0 0; padding: 0"
                                                                                off-icon="mdi-eye-off"
                                                                                on-icon="mdi-eye"
                                                                            />
                                                                        </div>
                                                                    </template>
                                                                    <span>Nutzer sehen geteilte Nutzer</span>
                                                                </v-tooltip>
                                                                <img
                                                                    :src="suchIcon"
                                                                    style="height: 18px"
                                                                    alt="suchen"
                                                                    class="iconToDarkBlue"
                                                                >
                                                            </div>
                                                        </template>
                                                    </v-text-field>
                                                </template>
                                                <v-treeview
                                                    v-model="selectedUsers"
                                                    style="background-color: white"
                                                    selectable
                                                    :items="allSelectableUsers"
                                                    selected-color="var(--v-chatColor-base)"
                                                    dense
                                                    return-object
                                                />
                                            </v-menu>
                                            <div
                                                v-else-if="disableEdit"
                                                class="mt-3"
                                            >
                                                <div
                                                    v-if="this.tempAccountsViewable && currentAccount.role !== 'parent'"
                                                    style="display: flex"
                                                >
                                                    <img
                                                        :src="multipleUsersIcon"
                                                        style="height: 20px;"
                                                        class="mr-1 iconToDarkBlue"
                                                        alt="Nutzer-Icon"
                                                    >
                                                    <span
                                                        style="font-size: 1rem; color: black"
                                                        class="pl-2"
                                                    >
                                                        <span
                                                            v-if="tempAccountsStrings.length === 1"
                                                        >
                                                            von {{ selectedEventCreatedBy.accountName }} mit Ihnen geteilt
                                                        </span>
                                                        <span v-else>
                                                            von {{ selectedEventCreatedBy.accountName }} geteilt mit:<br>
                                                            <span>
                                                                {{ sharedAccNamesToDisplay }}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                v-if="addRoom && !disableEdit"
                                                style="display: flex; align-items: center; height: 36px"
                                                class="mt-4"
                                            >
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-if="!disableEdit && addRoom"
                                                            v-model="tempRoomName"
                                                            placeholder="Raumname eintragen"
                                                            dense
                                                            hide-details
                                                            style="margin: 0"
                                                            :class="createBBB && !showNewCalendarEvent &&selectedEvent.bbbRoom ? 'mr-4' : ''"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <template #prepend>
                                                                <img
                                                                    :src="createBBB ? videoIcon : roomIcon"
                                                                    style="height: 20px; padding: 0; margin-left: 0 !important;"
                                                                    class="mx-2 iconToDarkBlue"
                                                                    :alt="createBBB ? 'BigBlueButton' : 'Raum'"
                                                                >
                                                            </template>
                                                            <template #append>
                                                                <div>
                                                                    <img
                                                                        :src="editIcon"
                                                                        style="height: 18px; color: #282f44;"
                                                                        alt="bearbeiten"
                                                                        class="iconToDarkBlue"
                                                                    >
                                                                </div>
                                                            </template>
                                                        </v-text-field>
                                                    </template>
                                                    <span>Name des Raums eingeben</span>
                                                </v-tooltip>
                                                <div
                                                    v-if="createBBB && !showNewCalendarEvent && selectedEvent.bbbRoom"
                                                    style="display: flex"
                                                >
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click="clickToggleIsOpen"
                                                            >
                                                                <img
                                                                    :src="bbbLocked ? lockIcon : unlockIcon"
                                                                    style="height: 20px;"
                                                                    :class="bbbLocked ? 'iconToDunkelgrau' : 'iconToDarkBlue'"
                                                                    :alt="bbbLocked ? 'Gesperrt' : 'Geöffnet'"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span v-if="bbbLocked">Raum öffnen</span>
                                                        <span v-else>Raum schließen</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click="joinBBBRoom"
                                                            >
                                                                <img
                                                                    :src="bbbLocked ? videoOffIcon : videoIcon"
                                                                    style="height: 20px;"
                                                                    :class="bbbLocked ? 'iconToDunkelgrau' : 'iconToGreen'"
                                                                    :alt="bbbLocked ? 'gesperrt' : 'beitreren'"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Videoraum beitreten</span>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                            <div
                                                style="display: flex"
                                                class="mt-4"
                                            >
                                                <v-tooltip
                                                    v-if="!disableEdit"
                                                    bottom
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="tempLink"
                                                            placeholder="Link eintragen"
                                                            dense
                                                            hide-details
                                                            style="margin: 0"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            <template #prepend>
                                                                <img
                                                                    :src="linkIcon"
                                                                    style="height: 20px; padding: 0; margin-left: 0 !important;"
                                                                    class="mx-2 iconToDarkBlue"
                                                                    alt="Link"
                                                                >
                                                            </template>
                                                            <template #append>
                                                                <div>
                                                                    <img
                                                                        :src="editIcon"
                                                                        style="height: 18px; color: #282f44;"
                                                                        alt="bearbeiten"
                                                                        class="iconToDarkBlue"
                                                                    >
                                                                </div>
                                                            </template>
                                                        </v-text-field>
                                                    </template>
                                                    <span>Link zu einer externen Webseite hinzufügen</span>
                                                </v-tooltip>
                                                <p
                                                    v-else-if="tempLink !== '' && currentAccount._id !== selectedEventCreatedBy._id"
                                                    class="ellipsis noselect"
                                                    style="font-size: medium;"
                                                    v-html="sanitizeHtml(urlify(tempLink))"
                                                />
                                                <div
                                                    v-else
                                                    style="display: flex;"
                                                >
                                                    <img
                                                        :src="noLinkIcon"
                                                        style="height: 20px;"
                                                        class="mr-1 iconToDarkBlue"
                                                        alt="Kein Link"
                                                    >
                                                    <p
                                                        class="ellipsis noselect pl-2"
                                                        style="font-size: medium;"
                                                    >
                                                        Kein Link vorhanden
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                style="background-color: #d3d3d3; color: black; padding: 2px; border-radius: 12px; width: 100%; min-height: 135px; max-height: 135px"
                                                class="mt-4"
                                            >
                                                <div
                                                    v-if="tempFiles.length > 0"
                                                    class="outsideBox"
                                                >
                                                    <div class="iconBox">
                                                        <img
                                                            :src="attachmentIcon"
                                                            style="height: 25px;"
                                                            class="iconToDarkBlue"
                                                            alt="Anhang"
                                                        >
                                                    </div>
                                                </div>
                                                <div
                                                    style="height: 90%"
                                                >
                                                    <v-textarea
                                                        v-if="!disableEdit"
                                                        v-model="tempNote"
                                                        type="text"
                                                        clearable
                                                        no-resize
                                                        hide-details
                                                        height="100px"
                                                        placeholder="Notiz eingeben"
                                                        solo
                                                        flat
                                                        background-color="transparent"
                                                        style="margin: 0 !important; padding: 0;"
                                                    />
                                                    <div v-else>
                                                        <p
                                                            style="height: 100px; overflow-y: scroll"
                                                            class="pa-2"
                                                        
                                                            v-html="tempNote === '' ? 'keine Notiz vorhanden' : sanitizeHtml(urlify(tempNote))"
                                                        />
                                                        <v-tooltip
                                                            :disabled="!showTooltips"
                                                            bottom
                                                        >
                                                            <template v-slot:activator="{ on }">
                                                                <div style="display: flex; align-items: center; font-size: medium; height: 10%; padding-top: 8px; justify-content: flex-end">
                                                                    <v-btn
                                                                        v-if="selectedOpen && disableEdit && (accountRole === 'pupil' || accountRole === 'parent')"
                                                                        :aria-hidden="me && me.accessibility && me.accessibility.screenreader"
                                                                        icon
                                                                        small
                                                                        class="pa-0 mr-2 preventColorInvert"
                                                                        style="height: 32px; border-radius: 4px !important; margin-top: -5px"
                                                                        v-on="on"
                                                                        @click="tempNote === '' ? readText('keine Notiz vorhanden', 'note') : readText(tempNote, 'note')"
                                                                    >
                                                                        <img
                                                                            :src="usedSpeakerIconNote"
                                                                            class="icon20"
                                                                            alt="Vorlesen"
                                                                        >
                                                                    </v-btn>
                                                                </div>
                                                            </template>
                                                            <span>Notiz vorlesen</span>
                                                        </v-tooltip>
                                                    </div>
                                                </div>

                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <div
                                                            v-if="!disableEdit"
                                                            style="display: flex; align-items: center; font-size: medium; height: 10%; padding-top: 8px; justify-content: flex-end"
                                                            class="clickable"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="$refs.fileInput.click()"
                                                        >
                                                            <input
                                                                ref="fileInput"
                                                                type="file"
                                                                hidden
                                                                multiple
                                                                @change="uploadFileChange"
                                                            >
                                                            <v-checkbox
                                                                v-model="filesSelected"
                                                                hide-details
                                                                dense
                                                                readonly
                                                                color="#282f44"
                                                                style="margin: 0 4px 0 0; padding: 0"
                                                                off-icon="mdi-plus"
                                                                on-icon="mdi-checkbox-marked-circle-outline"
                                                            />
                                                            <span class="pr-2">Anhang</span>
                                                        </div>
                                                    </template>
                                                    <span>Datei/-en als Anhang hochladen</span>
                                                </v-tooltip>
                                            </div>
                                            <div style="max-height: 190px; overflow-y: scroll; margin-top: 10px; padding: 0;">
                                                <v-list
                                                    style="padding: 0; width: 98%"
                                                    single-line
                                                >
                                                    <v-list-item
                                                        v-for="file in tempFiles"
                                                        :key="file.id"
                                                        class="pr-0 pl-0"
                                                    >
                                                        <div style="width: 100%; display: flex">
                                                            <div style="width: 70%; display: flex; align-items: center">
                                                                <v-icon
                                                                    v-if="file.mimetype === 'image/png'"
                                                                    style="color: #282f44; margin-right: 8px"
                                                                >
                                                                    mdi-file-png-box
                                                                </v-icon>
                                                                <v-icon
                                                                    v-else-if="file.mimetype === 'application/pdf'"
                                                                    style="color: #282f44; margin-right: 8px"
                                                                >
                                                                    mdi-file-pdf-box
                                                                </v-icon>
                                                                <v-icon
                                                                    v-else-if="jpgConditions.some(el => file.uploadedName.includes(el))"
                                                                    style="color: #282f44; margin-right: 8px"
                                                                >
                                                                    mdi-file-jpg-box
                                                                </v-icon>
                                                                <v-icon
                                                                    v-else-if="file['mimetype'].split('/')[0] === 'video'"
                                                                    style="color: #282f44; margin-right: 8px"
                                                                >
                                                                    mdi-file-video
                                                                </v-icon>
                                                                <v-icon
                                                                    v-else
                                                                    style="color: #282f44; margin-right: 8px"
                                                                >
                                                                    mdi-file
                                                                </v-icon>
                                                                <p>
                                                                    {{ file.uploadedName }}
                                                                </p>
                                                            </div>
                                                            <div style="width: 30%; display: flex; justify-content: flex-end">
                                                                <v-list-item-action
                                                                    v-if="selectedOpen && disableEdit && (accountRole === 'pupil' || accountRole === 'parent')"
                                                                    style="margin: 0"
                                                                >
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn
                                                                                :aria-hidden="me && me.accessibility && me.accessibility.screenreader"
                                                                                icon
                                                                                small
                                                                                class="pa-0 ml-2 preventColorInvert"
                                                                                style="height: 32px;"
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                                @click="readText(file.uploadedName, 'fileSpeakerIcon' + file._id)"
                                                                            >
                                                                                <img
                                                                                    :id="'fileSpeakerIcon' + file._id"
                                                                                    :src="lautsprecherIcon"
                                                                                    class="icon20 speaker"
                                                                                    alt="Vorlesen"
                                                                                >
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Datiename vorlesen</span>
                                                                    </v-tooltip>
                                                                </v-list-item-action>
                                                                <v-list-item-action style="margin: 0">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn
                                                                                icon
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                                @click="file['mimetype'].split('/')[0] === 'video' ? playVideo(file) : loadFullscreenPreview(file)"
                                                                            >
                                                                                <v-icon style="color: #282f44">
                                                                                    mdi-eye
                                                                                </v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Vorschau anzeigen</span>
                                                                    </v-tooltip>
                                                                </v-list-item-action>
                                                                <v-list-item-action style="margin: 0">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn
                                                                                icon
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                                @click="downloadFile(file, file.uploadedName)"
                                                                            >
                                                                                <img
                                                                                    :src="downloadIcon"
                                                                                    style="height: 20px;"
                                                                                    class="iconToDarkBlue"
                                                                                    alt="download-Icon"
                                                                                >
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Datei herunterladen</span>
                                                                    </v-tooltip>
                                                                </v-list-item-action>
                                                                <v-list-item-action
                                                                    v-if="!disableEdit"
                                                                    style="margin: 0"
                                                                >
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn
                                                                                icon
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                                @click="removeFile(file._id, file.uploadedName)"
                                                                            >
                                                                                <img
                                                                                    :src="deleteIcon"
                                                                                    style="height: 20px;"
                                                                                    class="iconToDarkBlue"
                                                                                    alt="Datei löschen"
                                                                                >
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Datei löschen</span>
                                                                    </v-tooltip>
                                                                </v-list-item-action>
                                                            </div>
                                                        </div>
                                                    </v-list-item>
                                                    <v-list-item
                                                        v-for="file in newFiles"
                                                        :key="file.id"
                                                        class="pr-0 pl-0"
                                                    >
                                                        <div style="display: flex; width: 100%">
                                                            <div style="width: 70%; display: flex; align-items: center">
                                                                <v-icon
                                                                    v-if="file.type === 'image/png'"
                                                                    style="color: #282f44; margin-right: 8px"
                                                                >
                                                                    mdi-file-png-box
                                                                </v-icon>
                                                                <v-icon
                                                                    v-else-if="file.type === 'application/pdf'"
                                                                    style="color: #282f44; margin-right: 8px"
                                                                >
                                                                    mdi-file-pdf-box
                                                                </v-icon>
                                                                <v-icon
                                                                    v-else-if="jpgConditions.some(el => file.name.includes(el))"
                                                                    style="color: #282f44; margin-right: 8px"
                                                                >
                                                                    mdi-file-jpg-box
                                                                </v-icon>
                                                                <v-icon
                                                                    v-else
                                                                    style="color: #282f44; margin-right: 8px"
                                                                >
                                                                    mdi-file
                                                                </v-icon>
                                                                <p>{{ file.name }}</p>
                                                            </div>
                                                            <div style="width: 30%; display: flex; justify-content: flex-end">
                                                                <!-- <v-list-item-action style="margin: 0">
                                                                    <v-btn
                                                                        icon
                                                                        @click="showFullImageNewFiles(file)"
                                                                    >
                                                                        <v-icon style="color: #282f44">
                                                                            mdi-eye
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </v-list-item-action> -->
                                                                <!-- <v-list-item-action
                                                                    v-if="!showNewCalendarEvent"
                                                                    style="margin: 0"
                                                                >
                                                                    <v-btn
                                                                        icon
                                                                        @click="downloadFile(file._id, file.name)"
                                                                    >
                                                                        <img
                                                                            :src="downloadIcon"
                                                                            style="height: 20px;"
                                                                            class="iconToDarkBlue"
                                                                            alt="download-Icon"
                                                                        >
                                                                    </v-btn>
                                                                </v-list-item-action> -->
                                                                <v-list-item-action
                                                                    style="margin: 0"
                                                                >
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn
                                                                                icon
                                                                                v-bind="attrs"
                                                                                v-on="on"
                                                                                @click="removeNewFile(file.name)"
                                                                            >
                                                                                <img
                                                                                    :src="deleteIcon"
                                                                                    style="height: 20px;"
                                                                                    class="iconToDarkBlue"
                                                                                    alt="Datei löschen"
                                                                                >
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Datei löschen</span>
                                                                    </v-tooltip>
                                                                </v-list-item-action>
                                                            </div>
                                                        </div>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <v-dialog
                v-if="showDeleteRepeatDialog"
                v-model="showDeleteRepeatDialog"
                persistent
                max-width="500px"
                style="z-index: 300"
            >
                <v-card
                    style="overflow: hidden; border-radius: 16px"
                >
                    <v-card-title
                        style="background-color: #282f44; color: white"
                        class="text-h5 mb-1 d-flex align-center justify-space-between"
                    >
                        <p>Termin löschen?</p>
                        <v-btn
                            icon
                            color="transparent"
                            elevation="0"
                            style="margin-right: -8px"
                            @click="showDeleteRepeatDialog = false"
                        >
                            <img
                                :src="cancelIcon"
                                class="icon30 iconToWhite"
                                alt="schließen"
                            >
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="pa-4">
                        Dieser Termin wird wiederholt.
                        <br>Möchten Sie nur diesen Termin löschen oder alle zugehörigen Termine?
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center mb-2">
                        <v-btn
                            class="optionBtn text-none ml-2"
                            color="#F5F5F5"
                            @click="showDeleteRepeatDialog = false"
                        >
                            <img
                                :src="cancelIcon"
                                class="icon20 mr-2"
                                alt="abbrechen"
                            >
                            Abbrechen
                        </v-btn>
                        <v-spacer />
                        <v-btn
                            class="optionBtn text-none ml-2"
                            color="rot"
                            dark
                            @click="() => {showDeleteDialog = true; deleteAllRepeat = false;}"
                        >
                            Nur dieses löschen
                        </v-btn>
                        <v-btn
                            class="optionBtn text-none ml-2"
                            color="rot"
                            dark
                            @click="() => {showDeleteDialog = true; deleteAllRepeat = true;}"
                        >
                            Alle löschen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Delete dialog-->
            <v-dialog
                v-if="showDeleteDialog"
                v-model="showDeleteDialog"
                persistent
                max-width="500"
                style="z-index: 300"
            >
                <v-card
                    style="overflow: hidden; border-radius: 16px"
                >
                    <v-card-title
                        style="background-color: #282f44; color: white"
                        class="text-h5 mb-1 d-flex align-center justify-space-between"
                    >
                        <p>Termin löschen?</p>
                        <v-btn
                            icon
                            color="transparent"
                            elevation="0"
                            style="margin-right: -8px"
                            @click="showDeleteDialog = false"
                        >
                            <img
                                :src="cancelIcon"
                                class="icon30 iconToWhite"
                                alt="schließen"
                            >
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="pa-4">
                        <div v-if="deleteAllRepeat">
                            <span>Sind Sie sicher, dass Sie diese Kalendereinträge löschen möchten?</span>
                            <br><span>Sie werden damit für alle Personen, mit denen sie geteilt wurden, gelöscht.</span>
                        </div>
                        <div v-if="!deleteAllRepeat">
                            <span>Sind Sie sicher, dass Sie diesen Kalendereintrag löschen möchten?</span>
                            <br><span>Er wird damit für alle Personen, mit denen er geteilt wurde, gelöscht.</span>
                        </div>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center mb-2">
                        <v-btn
                            class="optionBtn text-none ml-2"
                            color="#F5F5F5"
                            @click="showDeleteDialog = false"
                        >
                            <img
                                :src="cancelIcon"
                                class="icon20 mr-2"
                                alt="abbrechen"
                            >
                            Abbrechen
                        </v-btn>
                        <v-spacer />
                        <v-btn
                            class="optionBtn text-none ml-2"
                            color="rot"
                            dark
                            @click="() => {deleteAllRepeat ? deleteAllEvents() : deleteEvent();}"
                        >
                            <img
                                :src="deleteIcon"
                                class="icon20 iconToWhite mr-2"
                                alt="löschen"
                            >
                            Löschen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-dialog>
        <v-dialog
            v-if="showRepeatDialog"
            v-model="showRepeatDialog"
            persistent
            max-width="500px"
            style="z-index: 300"
        >
            <v-card
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    style="background-color: #282f44; color: white"
                    class="headline d-flex align-center justify-space-between"
                >
                    <p>Einzeln oder Alle?</p>
                    <v-btn
                        x-small
                        text
                        elevation="0"
                        class="pa-0"
                        @click="showRepeatDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 iconToWhite"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    Dieser Termin wird wiederholt.
                    <br>Möchten Sie die Änderungen nur für diesen Termin vornehmen oder auch für alle zugehörigen Termine?
                    <br>Hinweis: Wenn Sie nur dieses Bearbeiten werden Änderungen an der Wiederholung verworfen
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color=""
                        text
                        @click="showRepeatDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="red red-1"
                        text
                        @click="save"
                    >
                        Nur diesen
                    </v-btn>
                    <v-btn
                        color="red red-1"
                        text
                        @click="() => {editRepeat = true; save();}"
                    >
                        alle
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-if="showSaveNewDialog"
            v-model="showSaveNewDialog"
            persistent
            max-width="500px"
            style="z-index: 300"
        >
            <v-card
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    style="background-color: #282f44; color: white"
                    class="headline d-flex align-center justify-space-between"
                >
                    <p>
                        <span v-if="showNewCalendarEvent">Neues Event speichern?</span>
                        <span v-else>Änderungen speichern?</span>
                    </p>
                    <v-btn
                        x-small
                        text
                        elevation="0"
                        class="pa-0"
                        @click="showSaveNewDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 iconToWhite"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text style="padding-top: 12px">
                    Sie haben ungespeicherte Änderungen an diesem neuen Event!
                    <br>Möchten Sie die Änderungen speichern oder verwerfen?
                    <br><span v-if="showNewCalendarEvent">Hinweis: Wenn Sie die Änderungen verwerfen wird das neue Event entfernt.</span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="#F5F5F5"
                        @click="() => {showNewCalendarEvent ? dropNewEvent() : dropChanges()}"
                    >
                        <span>Verwerfen</span>
                    </v-btn>
                    <v-btn
                        color="var(--v-gruen-base)"
                        @click="save"
                    >
                        <span style="color: white">Speichern</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-if="showEmailDialog"
            v-model="showEmailDialog"
            persistent
            max-width="350px"
            style="z-index: 300"
        >
            <v-card
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    style="background-color: #282f44; color: white"
                    class="headline d-flex align-center justify-space-between"
                >
                    <p>
                        <span>Event per Email teilen</span>
                    </p>
                    <v-btn
                        x-small
                        text
                        elevation="0"
                        class="pa-0"
                        @click="showEmailDialog = false"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon20 iconToWhite"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="emailAdresses"
                        label="Emailadressen eingeben"
                    >
                        <template #prepend>
                            <img
                                :src="mailIcon"
                                style="height: 20px; padding: 0; margin-left: 0 !important;"
                                class="mx-2 iconToDarkBlue"
                                alt="E-Mail"
                            >
                        </template>
                    </v-text-field>
                    <div style="widht: 100%">
                        <div style="display: flex">
                            <div style="width: 10%; display: flex; align-items: center">
                                <img
                                    :src="infoIcon"
                                    style="height: 20px;"
                                    class="iconToDarkBlue"
                                    alt="Info"
                                >
                            </div>
                            <span style="width: 90%">Trennen Sie die Email-Adressen mit einem Komma ab, damit Sie richtig erkannt werden.</span>
                        </div>
                        <div
                            style="display: flex"
                            class="mt-1"
                        >
                            <div style="width: 10%; display: flex; align-items: center">
                                <img
                                    :src="infoIcon"
                                    style="height: 20px;"
                                    class="iconToDarkBlue"
                                    alt="Info"
                                >
                            </div>
                            <span style="width: 90%">Die iCal Datei muss separat als Anhang hinzugefügt werden!</span>
                        </div>
                    </div>
                    <div
                        style="display: flex; justify-content: space-between"
                        class="mt-1"
                    >
                        <v-btn
                            text
                            class="pa-0"
                            @click="showEmailDialog = false"
                        >
                            Abbrechen
                        </v-btn>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    text
                                    class="pa-0"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="createICal"
                                >
                                    <img
                                        :src="calendarIcon"
                                        style="height: 20px; padding: 0;"
                                        class="mr-1 iconToDarkBlue"
                                        alt="iCal"
                                    >
                                    iCal
                                </v-btn>
                            </template>
                            <span>iCal Datei herunterladen</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    text
                                    class="pa-0"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="openEmail"
                                >
                                    <img
                                        :src="mailIcon"
                                        style="height: 20px;"
                                        class="mx-2 iconToDarkBlue"
                                        alt="E-Mail"
                                    >
                                    Fertig
                                </v-btn>
                            </template>
                            <span>weiter zu Mailclient</span>
                        </v-tooltip>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <FilePreviewDialog
            v-if="enableFilePreview"
            ref="previewDialog"
            :files="previewFiles"
            :is-user-author="true"
            :enable-fullscreen-preview="enableFilePreview"
            :preview-selection="previewSelection"
            :preview-information="previewInformation"
            :preview-selection-index="previewSelectionIndex"
            @close="resetFilePreview"
            @cycleNext="cyclePreview(1)"
            @cyclePrev="cyclePreview(-1)"
            @download="downloadFile(previewSelection, previewSelection.uploadedName)"
        />
        <v-overlay
            absolute
            :value="showLoadingOverlay"
            style="width: 100% !important; z-index: 500 !important;"
        >
            <v-progress-circular
                v-if="showLoadingOverlay"
                indeterminate
                size="100"
            />
        </v-overlay>

        <!-- Fullscreen video dialog -->
        <v-dialog
            v-if="videoUrl"
            :value="videoUrl"
            overlay-opacity=".9"
            overlay-color="black"
            max-width="90%"
            width="unset"
            @click:outside="videoUrl = null; $refs.normalVideo.stop()"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        large
                        dark
                        @click="videoUrl = null; $refs.normalVideo.stop()"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <video-in-video
                        ref="normalVideo"
                        :primary-video-src="videoUrl"
                        :subtitle-src="subtitleUrl"
                        :fullscreen-mode="true"
                    />
                </v-col>
            </v-row>
        </v-dialog>
    </div>
</template>

<script>
import * as backend from '../../api/backend.js';

import sanitizeHtml from '@/util/sanitizeHtml';

import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import editIcon from '@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg';
import deleteIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import suchIcon from '@/assets/Icons/suche-26.svg';
import saveIcon from '@/assets/Icons/FaceliftIcons/speichern.svg';
import calendarIcon from '@/assets/Icons/FaceliftIcons/kalender_event_dtum.svg';
import calendarRepeatIcon from '@/assets/Icons/FaceliftIcons/kalender_woechentlich.svg';
import multipleUsersIcon from '@/assets/Icons/FaceliftIcons/nutzer_gruppen_default.svg';
import exportIcon from '@/assets/Icons/FaceliftIcons/export_teilen.svg';
import linkIcon from '@/assets/Icons/FaceliftIcons/verlinkung_aktiv.svg';
import noLinkIcon from '@/assets/Icons/FaceliftIcons/verlinkung_inaktiv.svg';
import downloadIcon from '@/assets/Icons/FaceliftIcons/herunterladen.svg';
import attachmentIcon from '@/assets/Icons/FaceliftIcons/anhang_senkrecht.svg';
import clockIcon from '@/assets/Icons/FaceliftIcons/stundenplan_uhr.svg';
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';
import mailIcon from '@/assets/Icons/FaceliftIcons/elternbriefe_zu_eingang.svg';
import videoIcon from '@/assets/Icons/video.svg';
import videoOffIcon from '@/assets/Icons/FaceliftIcons/video_gesperrt.svg';
import lockIcon from '@/assets/Icons/lock-closed.svg';
import unlockIcon from '@/assets/Icons/lock-open.svg';
import roomIcon from "@/assets/Icons/FaceliftIcons/raum.svg";
import optionsIcon from "@/assets/Icons/FaceliftIcons/kalender_datum_woche_monat_heute.svg";
import reminderOnIcon from '@/assets/Icons/FaceliftIcons/benachrichtigung_an.svg';
import reminderOffIcon from '@/assets/Icons/FaceliftIcons/benachrichtigung_aus.svg';
import Colorpicker from '@/components/Utils/ColorPicker';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import FilePreviewDialog from "@/components/Utils/FilePreviewDialog";
import VideoInVideo from "@/components/Utils/VideoInVideo";
import EventBus from "../../util/eventBus";
import {textToSpeech} from "@/util/textToSpeech";
import lautsprecherAusIcon from "@/assets/Icons/lautsprecher-aus-89.svg";
import lautsprecherIcon from "@/assets/Icons/lautsprecher-13.svg";
import ClickableBadge from "@/components/Utils/ClickableBadge";
import urlify from '../../util/urlify.js';

export default {
    name: "CalendarApp",
    components: {
        Colorpicker,
        FilePreviewDialog,
        VideoInVideo,
        ClickableBadge
    },

    data() {
        return {
            sanitizeHtml,

            editIcon,
            saveIcon,
            deleteIcon,
            cancelIcon,
            suchIcon,
            videoIcon,
            calendarIcon,
            calendarRepeatIcon,
            multipleUsersIcon,
            exportIcon,
            linkIcon,
            noLinkIcon,
            downloadIcon,
            attachmentIcon,
            clockIcon,
            infoIcon,
            mailIcon,
            videoOffIcon,
            lockIcon,
            unlockIcon,
            roomIcon,
            optionsIcon,
            reminderOnIcon,
            reminderOffIcon,
            lautsprecherIcon,
            lautsprecherAusIcon,

            usedSpeakerIcon: lautsprecherIcon,
            usedSpeakerIconNote: lautsprecherIcon,

            currentAccount: '',
            disableEdit: true,

            showDeleteDialog: false,
            showDeleteRepeatDialog: false,
            deleteAllRepeat: false,

            editRepeat: false,
            addRepeat: false,
            showRepeatDialog: false,

            showNewCalendarEvent: false,
            showSaveNewDialog: false,
            showEmailDialog: false,
            startDateMenu: false,
            startTimeMenu: false,
            endDateMenu: false,
            endTimeMenu: false,
            showTreeView: false,
            selectedOpen: false,
            selectedElement: null,
            selectedEvent: {},
            tempUpdatedEvent: {},

            selectableColors: ['#3ba934', '#fd4e27', '#803b8a', '#5782c2', '#5d5d5d', '#facc0f', '#e91e63', '#1b5e20', '#000000'],
            jpgConditions: ['.jpg', '.JPG', '.jpeg'],

            selectedEventCreatedBy: '',

            tempName: '',
            tempStartDate: '',
            tempStartTime: '',
            tempStartDateTime: null,
            tempStartDateString: '',
            timedTempStartTime: '',
            timedTempEndTime: '',
            tempEndDate: '',
            tempEndTime: '',
            tempEndDateTime: null,
            tempEndDateString: '',
            tempColor: null,
            tempTimed: false,
            tempAccounts: [],
            tempAccountsStrings: [],
            tempAccountsViewable: true,
            tempLink: '',
            tempNote: '',
            tempRepeat: 'nicht wiederholen',
            tempRepeatUntil: '',
            tempFiles: [],
            tempFilesIds: [],
            newFiles: [],
            deletedFiles: [],
            bbbLocked: false,
            addRoom: false,
            createBBB: false,
            tempBBBRoom: {},
            linkToBBB: '',
            createRoom: false,
            tempRoomName: '',
            tempRemind: 'keine Erinnerung',

            tempLinkedEvents: [],
            monthsToUpdate: [],

            allSelectableUsers: [],
            selectedUsers: [],
            teachers: [],
            groups: [],
            pupil: [],
            parents: [],
            filteredGroups: [],
            filteredPupils: [],
            filteredParents: [],

            openDialog: true,
            calendarType: 'month',
            events: [],
            allEvents: {},
            focus: '',
            typeToLabel: {
                month: 'Monat',
                week: 'Woche',
                day: 'Tag',
            },
            calendarTitle: '',
            focusDatePickerMode: 'month',

            emailAdresses: '',

            previewSelection: { name: 'Vorschau' },
            previewInformation: null,
            previewSelectionIndex: 0,
            enableFilePreview: false,
            previewFiles: [],

            prevMonth: 1,
            showLoadingOverlay: false,
            videoUrl: null,
            subtitleUrl: null,

            me: {
                accessibility: {
                    screenreader: false,
                }
            },

            showCalendarBadge: false,
            calendarBadgeEventList: [],
            badgeClicked: false,
            urlify,
        }
    },
    computed: {
        ...mapGetters("accounts", ["accountsById"]),
        ...mapGetters("teachers", ["teachersByAccountId"]),
        ...mapGetters('auth', ['accountRole']),
        ...mapState('util', ['windowWidth']),
        ...mapState('translation', ['targetLang']),
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),
        ...mapGetters('parents', ['currentChild']),

        filesSelected() {
            if (this.tempFiles.length === 0) {
                return false;
            } else {
                return true;
            }
        },

        enableReminder() {
            if (this.tempRemind === 'keine Erinnerung') {
                return false;
            } else {
                return true;
            }
        },

        sharedAccNamesToDisplay() {
            let accs = "";
            if (this.tempAccountsStrings.length > 3) {
                for (let i = 0; i < 3; i++) {
                    if (i === 0) {
                        accs = this.tempAccountsStrings[i];
                    } else if (i === 2) {
                        accs = accs + ', ' + this.tempAccountsStrings[i] + ', ...';
                    } else {
                        accs = accs + ', ' + this.tempAccountsStrings[i];
                    }
                }
            } else {
                for (let i = 0; i < this.tempAccountsStrings.length; i++) {
                    if (i === 0) {
                        accs = this.tempAccountsStrings[i];
                    } else {
                        accs = accs + ', ' + this.tempAccountsStrings[i];
                    }
                }
            }
            return accs;
        },
    },
    watch: {
        tempTimed: {
            handler() {
                // change times, when toggling timed
                if (!this.tempTimed) {
                    this.tempStartTime = this.timedTempStartTime;
                    this.tempEndTime = this.timedTempEndTime;
                } else {
                    this.timedTempStartTime = this.tempStartTime;
                    this.tempStartTime = '';
                    this.timedTempEndTime = this.tempEndTime;
                    this.tempEndTime = '';
                }
            }
        },
        tempStartDate: {
            handler() {
                // change date, when selected start date is after end date
                let changedStartDateObject = null;
                if (this.tempTimed) {
                    changedStartDateObject = new Date(this.tempStartDate);
                    this.tempStartDateTime = changedStartDateObject;
                } else {
                    changedStartDateObject = new Date(this.tempStartDate + 'T' + this.tempStartTime);
                    this.tempStartDateTime = changedStartDateObject;
                }

                if (changedStartDateObject.getDate() > this.tempEndDateTime.getDate()
                    && changedStartDateObject.getMonth() >= this.tempEndDateTime.getMonth())
                {
                    this.tempEndDate = changedStartDateObject.getFullYear() + '-' +
                        ('0' + (changedStartDateObject.getMonth() + 1)).slice(-2) + '-' +
                        ('0' + changedStartDateObject.getDate()).slice(-2);
                }
            }
        },
        tempStartTime: {
            handler() {
                // change time, when selected start time is after end time
                if (!this.tempTimed) {
                    let changedStartTimeObject = null;

                    changedStartTimeObject = new Date(this.tempStartDate + 'T' + this.tempStartTime);
                    this.tempStartDateTime = changedStartTimeObject;

                    if (changedStartTimeObject.getHours() > this.tempEndDateTime.getHours()
                        && changedStartTimeObject.getMinutes() >= this.tempEndDateTime.getMinutes())
                    {
                        const changedEndTimeObject = new Date(changedStartTimeObject.getTime() + 1 * 60 * 60 * 1000);
                        this.tempEndTime = ('0' + changedEndTimeObject.getHours()).slice(-2) + ":" +
                            ('0' + changedEndTimeObject.getMinutes()).slice(-2);
                    }
                }
            }
        },
        addRoom: {
            handler() {
                if (this.showNewCalendarEvent) {
                    if (!this.addRoom) {
                        this.createRoom = false;
                        this.createBBB = false;
                    } else {
                        this.createRoom = true;
                    }
                }
            }, immediate: true,
        },
    },
    async mounted() {
        await this.hasUserNewEvents();
        // init important things, that are always needed to use calendar
        await this.setToday();
        this.currentAccount = await this.getCurrentAccount();
        this.tempColor = this.currentAccount.profileColor ? this.currentAccount.profileColor : '#5782c2' ;
        EventBus.$off('calendar-new-event');
        EventBus.$on('calendar-new-event', (createdEvent) => {
            if (!createdEvent) {
                return;
            }
            this.events.push(createdEvent);
            this.calendarBadgeEventList.push({_id: createdEvent._id, start: createdEvent.start});
            this.calendarBadgeEventList = this.calendarBadgeEventList.sort((a, b) => new Date(a.start) - new Date(b.start));
        });
        EventBus.$off('calendar-delete-event');
        EventBus.$on('calendar-delete-event', (eventId) => {
            if (!eventId) {
                return;
            }
            this.events = this.events.filter((event) => event._id !== eventId);
            this.calendarBadgeEventList = this.calendarBadgeEventList.filter((event) => event._id !== eventId);
        });
        EventBus.$off('calendar-new-events');
        EventBus.$on('calendar-new-events', (createdEvents) => {
           createdEvents.forEach((event) => this.events.push(event));
           createdEvents.forEach((event) => this.calendarBadgeEventList.push({_id: event._id, start: event.start}));
           this.calendarBadgeEventList = this.calendarBadgeEventList.sort((a, b) => new Date(a.start) - new Date(b.start));
        });
        EventBus.$off('calendar-delete-events');
        EventBus.$on('calendar-delete-events', (eventIds) => {
            if (!eventIds || !eventIds.length) {
                return;
            }
            this.events = this.events.filter((event) => !eventIds.find((eventId) => eventId === event._id));
            this.calendarBadgeEventList = this.calendarBadgeEventList.filter((event) => !eventIds.find((eventId) => eventId === event._id));
        });
        EventBus.$off('calendar-change-event');
        EventBus.$on('calendar-change-event', (updatedEvent) => {
           this.events = this.events.map((event) => event._id === updatedEvent._id ? updatedEvent : event);
        });
        EventBus.$off('calendar-change-events-data');
        EventBus.$on('calendar-change-events-data', (updatedEvents) => {
           this.events = this.events.map((event) => {
               const foundUpdatedEvent = updatedEvents.find((updatedEvent) => updatedEvent._id === event._id);
               return foundUpdatedEvent ? foundUpdatedEvent : event;
           });
        });
        EventBus.$off('calendar-delete-file');
        EventBus.$on('calendar-delete-file', (params) => {
            const { fileId, eventId } = params;
            const foundEvent = this.events.find((event) => event._id === eventId);
            if (!foundEvent) {
                return;
            }
            foundEvent.files = foundEvent.files.filter((file) => file !== fileId);
        });
    },
    methods: {
        ...mapActions("calendar", ["getCalendarFileThumbnail","getCalendarEvents", "getCalendarEventById", "getCalendarEventsFromSerie", "getCalendarEventsForMonth", "deleteCalendarEvent", "deleteRepeatingEvent", "deleteCalendarReminder", "createNewCalendarEvent", "createNewReminder", "createNewRepeatingEvent", "editCalendarEvent", "editRepeatingEvent", "editRepeatingEventData", "setCalenderEventSeen", "getCalendarEventFile", "getCalendarEventFileInfo", 'getUnseenEventsForUser']),
        ...mapActions("pupils", ["getPupils", 'setPupilListStatus']),
        ...mapActions("maintainers", ["getMaintainers"]),
        ...mapActions("teachers", ["getTeachers", 'setTeachersInvalidStatus']),
        ...mapActions("parents", ["getParents"]),
        ...mapActions("groups", ["getGroups", 'setGroupInvalidStatus']),
        ...mapActions("auth", ["getCurrentAccount"]),
        ...mapActions('util', ['toggleLoading']),
        ...mapActions('mimeTypes', ['validateMimeType']),
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),
        ...mapMutations("snackbar", ["showSnackbar"]),


        async viewEvent({nativeEvent, event}){
            nativeEvent.stopPropagation();
            const open = async () => {
                this.tempAccountsStrings = [];
                this.tempAccounts = [];
                this.selectedUsers = [];
                this.selectedEvent = event;

                /** toggle disable edit rights for user
                *   gives users, that are only allowed to view event a different menu to view event
                */
                if (!this.showNewCalendarEvent) {
                    this.getCreatedBy(this.selectedEvent.createdBy);
                    if (this.currentAccount._id === this.selectedEventCreatedBy._id) {
                        this.disableEdit = false;
                    } else {
                        this.disableEdit = true;
                        this.setCalenderEventSeen([event._id]);
                        if (this.selectedEvent.originalColor) {
                            this.selectedEvent.color = this.selectedEvent.originalColor;
                        }
                        if (this.selectedEvent.originalName) {
                            this.selectedEvent.name = this.selectedEvent.originalName;
                        }
                    }
                    this.calendarBadgeEventList = this.calendarBadgeEventList.filter((el) => el._id !== this.selectedEvent._id);
                } else {
                    this.disableEdit = false;
                    this.addRoom = false;
                    this.createBBB = false;
                    this.createRoom = false;
                }

                // fill data to display and edit with the data from the selectedEvent
                this.tempStartDateTime = new Date(this.selectedEvent.start);
                this.tempEndDateTime = new Date(this.selectedEvent.end);

                this.tempName = this.selectedEvent.name;
                this.tempColor = this.selectedEvent.color;
                this.tempTimed = this.selectedEvent.timed;
                this.tempStartDate = this.tempStartDateTime.getFullYear() + '-' + ('0' + (this.tempStartDateTime.getMonth() + 1)).slice(-2) + '-' + ('0' + this.tempStartDateTime.getDate()).slice(-2);
                this.tempEndDate = this.tempEndDateTime.getFullYear() + '-' + ('0' + (this.tempEndDateTime.getMonth() + 1)).slice(-2) + '-' + ('0' + this.tempEndDateTime.getDate()).slice(-2);
                if (!this.tempTimed) {
                    this.tempStartTime = ('0' + this.tempStartDateTime.getHours()).slice(-2) + ":" + ('0' + this.tempStartDateTime.getMinutes()).slice(-2);
                    this.tempEndTime = ('0' + this.tempEndDateTime.getHours()).slice(-2) + ":" + ('0' + this.tempEndDateTime.getMinutes()).slice(-2);
                }
                this.tempStartDateString = this.tempStartDateTime.getDate() + '.' + ('0' + (this.tempStartDateTime.getMonth() + 1)).slice(-2) + '.' + this.tempStartDateTime.getFullYear();
                this.tempEndDateString = this.tempEndDateTime.getDate() + '.' + ('0' + (this.tempEndDateTime.getMonth() + 1)).slice(-2) + '.' + this.tempEndDateTime.getFullYear();
                this.tempRepeat = this.selectedEvent.repeat;
                this.tempRepeatUntil = this.selectedEvent.repeatUntil;
                this.tempAccounts = this.selectedEvent.accounts;
                this.tempAccountsViewable = this.selectedEvent.accountsViewable;
                this.tempLink = this.selectedEvent.link;
                this.tempNote = this.selectedEvent.note;

                this.tempFiles = this.selectedEvent.fileInfo || [];
                this.tempFilesIds = [];

                this.tempRemind = this.selectedEvent.reminder;

                if (this.selectedEvent.room) {
                    this.addRoom = true;
                    this.createRoom = true;
                    this.createBBB = false;
                    this.tempRoomName = this.selectedEvent.room;
                }

                if (this.selectedEvent.bbbRoom) {
                    this.addRoom = true;
                    this.createRoom = false;
                    this.createBBB = true;
                    await this.loadBBBRoom();
                    this.tempRoomName = this.tempBBBRoom.name;
                }

                // doesn't need to be done, when creating new event, since there is no existing files
                if (!this.showNewCalendarEvent && !this.selectedEvent.fileInfo) {

                    this.tempFilesIds = this.selectedEvent.files;
                    this.tempFiles = await this.tempFilesIds.reduce(async (prevPromise, file) => {
                        const prev = await prevPromise;
                        const res = await this.getCalendarEventFileInfo({fileId: file._id, fileAccessToken: file.accessToken});
                        const info = await res.json();
                        prev.push(info);
                        return prev;
                    }, Promise.resolve([]));

                    const index = this.events.findIndex((element) => element._id === this.selectedEvent._id);
                    this.events[index].fileInfo = this.tempFiles;
                }

                // get all selectable users if there is non yet (also fills treeview)
                if (this.pupil.length === 0
                    || this.parents.length === 0
                    || this.teachers.length === 0)
                {
                    await this.initUsers();
                }
                // pre-select users, that event was already shared with
                this.allSelectableUsers.map((element) => {
                    if (element.name !== 'Verwaltung') {
                        element.children.map((child) => {
                            this.tempAccounts.map((el) => {
                                if (el === child.id) {
                                    this.selectedUsers.push(child);
                                }
                            });
                        });
                    } else {
                        if (this.tempAccounts.includes(element.id)) {
                            this.selectedUsers.push(element);
                        }
                    }
                });
                // generates strings for all users, that event was shared with
                this.allSelectableUsers.map((category) => {
                    if (category.name !== 'Verwaltung') {
                        category.children.map((user) => {
                            if (user.children) {
                                user.children.map((child) => {
                                    if (this.tempAccounts.includes(child.id)) {
                                        this.tempAccountsStrings.push(child.name);
                                    }
                                });
                            } else {
                                if (this.tempAccounts.includes(user.id)) {
                                    this.tempAccountsStrings.push(user.name);
                                }
                            }

                        });
                    } else {
                        if (this.tempAccounts.includes(category.id)) {
                            this.tempAccountsStrings.push('Verwaltung');
                        }
                    }
                });

                this.selectedElement = nativeEvent.target;
                this.selectedOpen = true;
            }

            if (this.selectedOpen) {
                // reset data when closing event
                this.disableEdit = true;

                this.tempName = '';
                this.tempStartDate = '';
                this.tempStartTime = '';
                this.tempStartDateTime = null;
                this.tempEndDateTime = null;
                this.tempEndDate= '';
                this.tempEndTime = '';
                this.tempColor = this.currentAccount.profileColor ? this.currentAccount.profileColor : '#5782c2' ;
                this.tempTimed = false;
                this.tempAccounts = [];
                this.tempAccountsStrings = [];
                this.tempAccountsViewable = true;
                this.tempFiles = [];
                this.tempFilesIds = [];
                this.newFiles = [];
                this.deletedFiles = [];
                this.tempLink = '';
                this.tempNote = '';
                this.tempRepeat = 'nicht wiederholen';
                this.tempRepeatUntil = '';

                this.selectedOpen = false;
                this.selectedElement = null;
                this.selectedEvent.originalColor = null;

                this.addRoom = false;
                this.createBBB = false;
                this.createRoom = false;
                this.tempRoomName = '';

                this.tempRemind = 'keine Erinnerung';

                // requestAnimationFrame(() => requestAnimationFrame(() => open()));
                await this.$nextTick(async () => { await open() });
            } else {
                await open();
            }
        },
        getCreatedBy(id){
            this.selectedEventCreatedBy = this.accountsById[id];
        },
        viewDay({ date }) {
            this.focus = date;
            this.calendarType = 'day';
            this.focusDatePickerMode = 'date';
        },
        async setToday() {
            const date = new Date();
            let todayAsString = date.getFullYear() + '-' + (date.getMonth()+1) + "-" + date.getDate();
            this.focus = todayAsString;
            this.changeTitle();
        },
        newCalendarEntry() {
            this.showNewCalendarEvent = true;
            const date = new Date();
            const currentDateTimeString = date.getFullYear() + '-' + ('0' + (date.getMonth()+1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + 'T' + ('0' + date.getHours()).slice(-2) + ":00";
            const endDateTime = new Date(date.getTime() + 1 * 60 * 60 * 1000);
            const endDateTimeString = endDateTime.getFullYear() + '-' + ('0' + (endDateTime.getMonth()+1)).slice(-2) + '-' + ('0' + endDateTime.getDate()).slice(-2) + 'T' + ('0' + endDateTime.getHours()).slice(-2) + ":00";

            let data = {
                name: '',
                start: currentDateTimeString,
                end: endDateTimeString,
                color: this.currentAccount.profileColor ? this.currentAccount.profileColor : '#5782c2',
                timed: false,
                link: '',
                note: '',
                accounts: [],
                accountsViewable: true,
                repeat: 'nicht wiederholen',
                reminder: 'keine Erinnerung',
            }

            this.events.push(data);

            // simulate mouse click to instantly open newly created event
            let simulatedEvent = new MouseEvent("click", {view: window, bubbles: true, cancelable: true});
            // target cant be defined, therefore we need to manually overwrite it
            if (this.windowWidth > 900) {
                Object.defineProperty(simulatedEvent, 'target', { value: document.getElementById('newEventMount') });
            } else {
                Object.defineProperty(simulatedEvent, 'target', { value: document.getElementById('newEventMountMobile')});
            }
            this.viewEvent({ nativeEvent: simulatedEvent, event: this.events[this.events.length -1] });
        },
        newCalendarEntryAt( event ) {
            this.showNewCalendarEvent = true;
            const currentDateTimeString = event.date + 'T' + "08:00";
            const endDateTimeString = event.date + 'T' + "09:00";

            let data = {
                name: '',
                start: currentDateTimeString,
                end: endDateTimeString,
                color: this.currentAccount.profileColor ? this.currentAccount.profileColor : '#5782c2',
                timed: false,
                link: '',
                note: '',
                accounts: [],
                accountsViewable: true,
                repeat: 'nicht wiederholen',
                reminder: 'keine Erinnerung',
            }

            this.events.push(data);

            // simulate mouse click to instantly open newly created event
            let simulatedEvent = new MouseEvent("click", {view: window, bubbles: true, cancelable: true});
            // target cant be defined, therefore we need to manually overwrite it
            if (this.windowWidth > 900) {
                Object.defineProperty(simulatedEvent, 'target', { value: document.getElementById('newEventMount') });
            } else {
                Object.defineProperty(simulatedEvent, 'target', { value: document.getElementById('newEventMountMobile')});
            }
            this.viewEvent({ nativeEvent: simulatedEvent, event: this.events[this.events.length -1] });
        },
        newCalendarEntryAtTime(event) {
            this.showNewCalendarEvent = true;
            const currentDateTimeString = event.date + 'T' + ('0' + event.hour).slice(-2) + ":00";
            let endHour = event.hour + 1;
            if(endHour === '24') {
                endHour = 0;
            }

            const endDateTimeString = event.date + 'T' + ('0' + endHour).slice(-2) + ":00";

            let data = {
                name: 'Neuer Termin',
                start: currentDateTimeString,
                end: endDateTimeString,
                color: this.currentAccount.profileColor ? this.currentAccount.profileColor : '#5782c2',
                timed: false,
                link: '',
                note: '',
                accounts: [],
                accountsViewable: true,
                repeat: 'nicht wiederholen',
                reminder: 'keine Erinnerung',
            }

            this.events.push(data);

            // simulate mouse click to instantly open newly created event
            let simulatedEvent = new MouseEvent("click", {view: window, bubbles: true, cancelable: true});
            // target cant be defined, therefore we need to manually overwrite it
            if (this.windowWidth > 900) {
                Object.defineProperty(simulatedEvent, 'target', { value: document.getElementById('newEventMount') });
            } else {
                Object.defineProperty(simulatedEvent, 'target', { value: document.getElementById('newEventMountMobile')});
            }
            this.viewEvent({ nativeEvent: simulatedEvent, event: this.events[this.events.length -1] });
            this.tempName = '';
        },
        async deleteEvent() {
            this.showLoadingOverlay = true;
            let response = await this.deleteCalendarEvent(this.selectedEvent._id);

            if (!response) {
                this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                return;
            } else {
                this.showSnackbar({ message: 'Löschen erfolgreich!'});
                let deletedEvent = await response.json();
                this.deleteCalendarReminder(deletedEvent._id);
                if (deletedEvent.bbbRoom) {
                    const bbbRes = await backend.deleteExternalBbbRoom(deletedEvent.bbbRoom);
                    if (!bbbRes) {
                        this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                        return;
                    }
                }
                const monthOfDeleted = this.selectedEvent.start.substring(0,7);
                this.allEvents[monthOfDeleted] = await this.getCalendarEventsForMonth(monthOfDeleted);
                if (this.selectedEvent.start !== this.selectedEvent.end) {
                    // if event ends in another month update that one as well
                    const endMonthOfDeleted = this.selectedEvent.end.substring(0,7);
                    this.allEvents[endMonthOfDeleted] = await this.getCalendarEventsForMonth(endMonthOfDeleted);
                }
                this.updateEvents();
                this.selectedOpen = false;
                this.selectedElement = null;
                this.selectedUsers = [];
                this.showDeleteDialog = false;
                this.showDeleteRepeatDialog = false;
                this.disableEdit = true;
                this.showLoadingOverlay = false;
            }
        },
        async deleteAllEvents() {
            this.showLoadingOverlay = true;
            let response = await this.deleteRepeatingEvent(this.selectedEvent.serie);

            if (!response) {
                this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                return;
            } else {
                const deletedEvents = await response.json();
                await Promise.all(deletedEvents.map(async (el) => {
                    this.deleteCalendarReminder(el._id);
                    if (el.bbbRoom) {
                        const bbbRes = await backend.deleteExternalBbbRoom(el.bbbRoom);
                        if (!bbbRes) {
                            this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                            return;
                        }
                    }
                    const monthOfDeleted = el.start.substring(0,7);
                    this.allEvents[monthOfDeleted] = await this.getCalendarEventsForMonth(monthOfDeleted);
                    if (el.start !== el.end) {
                        // if event ends in another month update that one as well
                        const endMonthOfDeleted = el.end.substring(0,7);
                        this.allEvents[endMonthOfDeleted] = await this.getCalendarEventsForMonth(endMonthOfDeleted);
                    }
                }));
                this.showSnackbar({ message: 'Löschen erfolgreich!'});
                this.updateEvents();
                this.deleteAllRepeat = false;
                this.selectedOpen = false;
                this.selectedElement = null;
                this.selectedUsers = [];
                this.showDeleteDialog = false;
                this.showDeleteRepeatDialog = false;
                this.disableEdit = true;
            }
            this.showLoadingOverlay = false;
        },
        async clickDownload() {
            //method to download all files of event in case its ever needed
            await Promise.all(this.selectedEvent.files.map(async (file) => {
                const res = await this.getCalendarEventFile({
                    fileId: file._id,
                    fileAccessToken: file.accessToken,
                });
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = url;
                const fileInfoRes = await this.getCalendarEventFileInfo({
                    fileId: file._id,
                    fileAccessToken: file.accessToken,
                });
                const fileInfo = await fileInfoRes.json();
                a.download = fileInfo.originalname;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function () {
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);
            }));
        },
        notifyClose() {
            this.$emit('close');
        },
        async nextPage() {
            this.$refs.calendar.next();
            this.changeTitle();
        },

        async previousPage() {
            this.$refs.calendar.prev();
            this.changeTitle();
        },
        async calendarChange(event) {
            // checks if month has already been loaded still need work
            // currently always reloads a month, even when already viewed once
            if (this.calendarType === 'month') {
                let month = '';
                month = event.start.year + '-' + ('0' + event.start.month).slice(-2);
                if(!this.allEvents[month]) {
                    const res = await this.getCalendarEventsForMonth(month);
                    const adjustedEvents = res.map((event) => {
                        if (event.newTo.includes(this.currentAccount._id)) {
                            event.originalColor = event.color;
                            event.originalName = event.name;
                            event.name = `*NEU* ${event.originalName}`;
                            event.color = '#dd0718';
                        }
                        return event;
                    });
                    //this.events = res;
                    this.allEvents[month] = adjustedEvents;
                    this.updateEvents();
                }
                this.prevMonth = event.start.month;
            } else if (this.calendarType === 'week') {
                // week view -> check if month changed and get events for new month
                if (event.start.month !== event.end.month) {
                    this.events = [];
                    let startMonth = '';
                    startMonth = event.start.year + '-' + ('0' + event.start.month).slice(-2);
                    const resStart = await this.getCalendarEventsForMonth(startMonth);
                    this.events = resStart;

                    let endMonth = '';
                    endMonth = event.end.year + '-' + ('0' + event.end.month).slice(-2);
                    const res = await this.getCalendarEventsForMonth(endMonth);
                    const adjustedEvents = res.map((event) => {
                        if (event.newTo.includes(this.currentAccount._id)) {
                            event.originalColor = event.color;
                            event.color = '#dd0718';
                        }
                        return event;
                    });
                    this.allEvents[endMonth] = adjustedEvents;
                    this.prevMonth = event.start.month;
                    this.updateEvents();
                } else if (event.start.month !== this.prevMonth) {
                    let month = '';
                    month = event.start.year + '-' + ('0' + event.start.month).slice(-2);
                    const res = await this.getCalendarEventsForMonth(month);
                    const adjustedEvents = res.map((event) => {
                        if (event.newTo.includes(this.currentAccount._id)) {
                            event.originalColor = event.color;
                            event.color = '#dd0718';
                        }
                        return event;
                    });
                    this.allEvents[month] = adjustedEvents;
                    this.prevMonth = event.start.month;
                    this.updateEvents();
                }
            } else {
                // day view -> check if month changed and get events for new month
                if (event.start.month !== this.prevMonth) {
                    let month = '';
                    month = event.start.year + '-' + ('0' + event.start.month).slice(-2);
                    const res = await this.getCalendarEventsForMonth(month);
                    const adjustedEvents = res.map((event) => {
                        if (event.newTo.includes(this.currentAccount._id)) {
                            event.originalColor = event.color;
                            event.color = '#dd0718';
                        }
                        return event;
                    });
                    this.allEvents[month] = adjustedEvents;
                    this.prevMonth = event.start.month;
                    this.updateEvents();
                }
            }
        },
        async changeTitle() {
            await this.$refs.calendar.checkChange();
            this.calendarTitle = this.$refs.calendar.title;
        },

        async initUsers() {
            this.allSelectableUsers = [];
            this.selectedUsers = [];
            await this.requestMaintainers();
            await this.requestTeachers();
            await this.requestGroups();
            await this.requestPupil();
            await this.requestParents();
            if(this.accountRole !== 'parent' && this.accountRole !== 'pupil') {
                this.fillTreeViewUsers();
            }
        },

        async requestMaintainers() {
            this.maintainers = await this.getMaintainers();
        },

        async requestTeachers() {
            this.teachers = await this.getTeachers();
        },

        async requestGroups() {
            if (this.currentAccount.role === 'teacher') {
                const allGroups = await this.getGroups();
                const teacher = this.teachersByAccountId[this.currentAccount._id];
                this.filteredGroups = allGroups.filter(group => group.leaders.includes(teacher._id));
                this.groups = this.filteredGroups;
            } else {
                this.groups = await this.getGroups();
            }
        },

        async requestPupil() {
            if (this.currentAccount.role === 'maintainer') {
                this.pupil = await this.getPupils();
            } else {
                for (let i = 0; i < this.filteredGroups.length; i++) {
                    for (let j = 0; j < this.filteredGroups[i].participants.length; j++) {
                        let included = false;
                        this.filteredPupils.map((pupil) => {
                            if (pupil._id === this.filteredGroups[i].participants[j]._id) {
                                included = true;
                            }
                        });
                        if (!included) {
                            this.filteredPupils.push(this.filteredGroups[i].participants[j]);
                        }
                    }
                }
                this.pupil = this.filteredPupils;
            }
        },

        async requestParents() {
            if (this.currentAccount.role === 'maintainer') {
                this.parents = await this.getParents(true);
            } else {
                const allParents = await this.getParents(true);
                for(let i = 0; i < allParents.length; i++) {
                    this.filteredPupils.map((pupil) => {
                        if (allParents[i].pupils.includes(pupil._id) && !this.filteredParents.some((el) => el._id === allParents[i]._id)) {
                            this.filteredParents.push(allParents[i]);
                        }
                    });
                }
                this.parents = this.filteredParents;
            }
        },

        fillTreeViewUsers() {
            let indexUsers = 0;
            
            if (this.accountRole !== 'maintainer') {
                // add maintainer
                this.allSelectableUsers.push({
                    id: this.maintainers[indexUsers].account,
                    name: 'Verwaltung',
                });
                indexUsers++;
            }
            // add teachers heading
            this.allSelectableUsers.push({
                id: 'teacherHeading',
                name: 'Lehrer',
                children: [],
            });
            // add teachers data to child array of teacher heading
            this.teachers.forEach((el) => {
                if (el.account !== this.currentAccount._id) {
                    this.allSelectableUsers[indexUsers].children.push(
                        {
                            id: el.account,
                            name: el.name + ' ' + el.lastName,
                        }
                    )
                }
            });
            indexUsers++;

            // add groups heading
            this.allSelectableUsers.push({
                id: 'groupsHeading',
                name: 'Gruppen',
                children: [],
            });
            // add groups data to child array of groups heading
            this.groups.forEach((el) => {
                if (el.name !== 'Alle') {
                    let tempArray = [];
                    el.participants.forEach((element) => {
                        tempArray.push({
                            id: element.account,
                            name: element.name + ' ' + element.lastName,
                        });
                    });
                    this.allSelectableUsers[indexUsers].children.push(
                        {
                            id: el._id,
                            name: el.name,
                            children: tempArray,
                        }
                    );
                }
            });
            indexUsers++;
            // add pupil heading
            this.allSelectableUsers.push({
                id: 'pupilHeading',
                name: 'Schüler',
                children: [],
            });
            // add pupil data to child array of pupil heading
            this.pupil.forEach((el) => {
                this.allSelectableUsers[indexUsers].children.push(
                    {
                        id: el.account,
                        name: el.name + ' ' + el.lastName,
                    }
                )
            });
            indexUsers++;
            // add parents heading
            this.allSelectableUsers.push({
                id: 'parentsHeading',
                name: 'Eltern',
                children: [],
            });

            // add parents of groups
            this.groups.forEach((el) => {
                if (el.name !== 'Alle') {
                    let tempArray = [];
                    el.participants.forEach((element) => {
                        if(element.parent && this.parents) {
                            const parent = this.parents.find((elParent) => elParent._id === element.parent);
                            const index = tempArray.findIndex((parentInTemp) => parentInTemp.id === parent.account);
                            if(index === -1) {
                                tempArray.push({
                                    id: parent.account,
                                    name: "Eltern von " + element.name + ' ' + element.lastName,
                                });
                            } else {
                                tempArray[index] = {
                                    id: parent.account,
                                    name: tempArray[index].name + ", " + element.name + ' ' + element.lastName,
                                }
                            }
                        }
                    });
                    this.allSelectableUsers[indexUsers].children.push(
                        {
                            id: el._id + "-parents",
                            name: "Eltern " + el.name,
                            children: tempArray,
                        }
                    );
                }
            });

            // add parents data to child array of parents heading
            this.parents.forEach((el) => {
                el.pupils.map((pupil) => {
                    this.pupil.map((element) => {
                        if (pupil === element._id) {
                            const index = this.allSelectableUsers[indexUsers].children.findIndex((parentInAll) => parentInAll.id === el.account);
                            if(index === -1) {
                                this.allSelectableUsers[indexUsers].children.push({
                                    id: el.account,
                                    name: 'Eltern von ' + element.name + ' ' + element.lastName,
                                });
                            } else {
                                this.allSelectableUsers[indexUsers].children[index] = {
                                    id: el.account,
                                    name: this.allSelectableUsers[indexUsers].children[index].name + ", " + element.name + ' ' + element.lastName,
                                }
                            }

                        }
                    });
                });
            });
        },
        async uploadFileChange() {
            let files = Array.from(this.$refs.fileInput.files);
            if (await this.validateMimeType(files)) {
                files.map((file) => {
                    this.newFiles.push(file);
                });
            }
        },
        async downloadFile(file, name) {
            const res = await this.getCalendarEventFile({
                fileId: file._id,
                fileAccessToken: file.accessToken
            });
            let blob = await res.blob();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = name;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            setTimeout(function () {
                a.remove();
                window.URL.revokeObjectURL(url);
            }, 1000);
        },
        removeFile(id, name) {
            const indexId = this.tempFilesIds.indexOf(id);
            this.deletedFiles.push(id);
            this.tempFilesIds.splice(indexId, 1);
            let indexName;
            for (let i = 0; i < this.tempFiles.length; i++) {
                if (this.tempFiles[i].name === name) {
                    indexName = i;
                    break;
                }
            }
            this.tempFiles.splice(indexName, 1);
        },
        removeNewFile(name) {
            let index;
            for (let i = 0; i < this.newFiles.length; i++) {
                if (this.newFiles[i].name === name) {
                    index = i;
                    break;
                }
            }
            this.newFiles.splice(index, 1);
        },
        checkSaveClick() {
            if (this.showNewCalendarEvent) {
                this.save();
            } else if (this.tempRepeat === 'nicht wiederholen' && !this.showNewCalendarEvent) {
                this.save();
            } else if (this.selectedEvent.repeat === 'nicht wiederholen' && this.tempRepeat !== 'nicht wiederholen') {
                this.addRepeat = true;
                this.save();
            } else {
                this.showRepeatDialog = true;
            }
        },
        filterTempAccounts(accounts){
            const filtered = accounts.filter(account => !(account.includes('-parents')));
            const unique = [...new Set(filtered)];
            return unique;
        },
        async save(){
            this.showLoadingOverlay = true;
            const finalStartDateTime = this.tempTimed ? this.tempStartDate : (this.tempStartDate + 'T' + this.tempStartTime);
            const finalEndDateTime = this.tempTimed ? this.tempEndDate : (this.tempEndDate + 'T' + this.tempEndTime);

            // clear tempAccounts (still has initial array not the changes)
            this.tempAccounts = [];

            // fill with changed selected users
            this.selectedUsers.forEach((el) => {
                this.tempAccounts.push(el.id);
            });
            try {
                if (this.validateInput()) {
                    if (!this.showNewCalendarEvent) {
                        // save already existing event -> edit mode
                        if (this.addRepeat) {
                            // event previously had no repeat and has repeat after changes -> needs to be treated differently
                            if (this.tempRepeat === 'wöchentlich') {
                                await this.createWeekly(finalStartDateTime, finalEndDateTime);
                            } else {
                                await this.createMonthly(finalStartDateTime, finalEndDateTime);
                            }
                            // delete existing event and then create newly created events
                            this.deleteEvent();
                            let response = await this.createNewRepeatingEvent({files: this.newFiles, events: this.tempLinkedEvents});

                            this.tempLinkedEvents = [];
                            this.tempRepeatUntil = '';
                            this.selectedUsers = [];
                            this.tempAccounts = [];

                            if (response.status === 201) {
                                this.showSnackbar({ message: 'Gespeichert!'});
                                this.showNewCalendarEvent = false;
                            } else {
                                this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                            }
                        } else if (this.editRepeat) {
                            // edit all events that belong to same serie
                            if (this.tempRepeat !== this.selectedEvent.repeat || this.tempRepeatUntil !== this.selectedEvent.repeatUntil
                                || finalStartDateTime !== this.selectedEvent.start || finalEndDateTime !== this.selectedEvent.end
                                || this.tempTimed !== this.selectedEvent.timed) {
                                // repeat pattern changed -> create new events
                                if (this.tempRepeat === 'wöchentlich') {
                                    await this.createWeekly(finalStartDateTime, finalEndDateTime);
                                } else {
                                    await this.createMonthly(finalStartDateTime, finalEndDateTime);
                                }
                                if (this.selectedEvent.reminder) {
                                    // delete old reminders
                                    const eventsFromSerie = this.getCalendarEventsFromSerie(this.selectedEvent.serie);
                                    await Promise.all(eventsFromSerie.map(async (event) => {
                                        this.deleteCalendarReminder(event._id);
                                    }));
                                }
                                let response = await this.editRepeatingEvent({ serie: this.selectedEvent.serie, body: this.tempLinkedEvents});

                                if (response.status === 200) {
                                    if (this.enableReminder) {
                                        // create new reminders for events in serie
                                        let resJs = await response.json();
                                        let remindAt = this.tempStartDateTime;
                                        remindAt = this.getRemindAt(remindAt);
                                        await Promise.all(resJs.map(async (createdEvent) => {
                                            let reminderRes = await this.createNewReminder({
                                                accounts: this.tempAccounts,
                                                calendarEventId: createdEvent._id,
                                                remindAt,
                                                fullDay: this.tempTimed,
                                            });
                                        }));
                                    }
                                    this.showSnackbar({ message: 'Änderungen gespeichert!'});
                                    this.showNewCalendarEvent = false;
                                    this.showRepeatDialog = false;
                                } else {
                                    this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                                }
                            } else {
                                // repeat pattern not changed -> simply update name, color, link, note, accounts, newTo
                                let data = {
                                    _id: this.selectedEvent._id,
                                    name: this.tempName,
                                    color: this.tempColor,
                                    link: this.tempLink,
                                    note: this.tempNote,
                                    accounts: this.filterTempAccounts(this.tempAccounts),
                                    accountsViewable: this.tempAccountsViewable,
                                    newTo: this.filterTempAccounts(this.tempAccounts),
                                    reminder: this.tempRemind,
                                }

                                if (this.createRoom) {
                                    data.room = this.tempRoomName;
                                }

                                const bbbRes = await this.checkBBBChange();
                                if (bbbRes.change === 'roomNameChange') {
                                    this.showSnackbar({ message: 'Änderungen an BBB Raum Name gespeichert!'});
                                } else if (bbbRes.change === 'roomCreated') {
                                    // handled in checkBBBChange -> otherwise all would have same bbb room
                                } else if (bbbRes.change === 'roomDeleted') {
                                    data.bbbRoom = undefined;
                                } else {
                                    // no change nothing to do
                                }
                                if (this.selectedEvent.reminder) {
                                    // delete old reminders
                                    const eventsFromSerie = this.getCalendarEventsFromSerie(this.selectedEvent.serie);
                                    await Promise.all(eventsFromSerie.map(async (event) => {
                                        this.deleteCalendarReminder(event._id);
                                    }));
                                }

                                let response = await this.editRepeatingEventData({serie: this.selectedEvent.serie, data});

                                if (response.status === 200) {
                                    if (this.enableReminder) {
                                        // create new reminders for events in serie
                                        let resJs = await response.json();
                                        let remindAt = this.tempStartDateTime;
                                        remindAt = this.getRemindAt(remindAt);
                                        await Promise.all(resJs.map(async (createdEvent) => {
                                            let reminderRes = await this.createNewReminder({
                                                accounts: this.tempAccounts,
                                                calendarEventId: createdEvent._id,
                                                remindAt,
                                                fullDay: this.tempTimed,
                                            });
                                        }));
                                    }
                                    this.showSnackbar({ message: 'Änderungen gespeichert!'});
                                    this.showNewCalendarEvent = false;
                                    this.showRepeatDialog = false;
                                } else {
                                    this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                                }
                            }
                        } else {
                            // edit only this event
                            let data = {
                                _id: this.selectedEvent._id,
                                name: this.tempName,
                                start: finalStartDateTime,
                                end: finalEndDateTime,
                                color: this.tempColor,
                                timed: this.tempTimed,
                                link: this.tempLink,
                                note: this.tempNote,
                                accounts: this.filterTempAccounts(this.tempAccounts),
                                accountsViewable: this.tempAccountsViewable,
                                newTo: this.filterTempAccounts(this.tempAccounts),
                                createdBy: this.currentAccount._id,
                                reminder: this.tempRemind,
                            }

                            if (this.createRoom) {
                                data.room = this.tempRoomName;
                            }

                            const bbbRes = await this.checkBBBChange();
                            if (bbbRes.change === 'roomNameChange') {
                                this.showSnackbar({ message: 'Änderungen an BBB Raum Name gespeichert!'});
                            } else if (bbbRes.change === 'roomCreated') {
                                data.bbbRoom = bbbRes.room._id;
                                data.room = undefined;
                                this.showSnackbar({ message: 'BBB Raum erstellt!'});
                            } else if (bbbRes.change === 'roomDeleted') {
                                data.bbbRoom = undefined;
                            } else {
                                // no change nothing to do
                            }

                            // delete old reminder
                            if (this.selectedEvent.reminder) {
                                await this.deleteCalendarReminder(data._id);
                            }

                            let response = await this.editCalendarEvent({data, newFiles: this.newFiles, deletedFiles: this.deletedFiles});

                            this.tempUpdatedEvent = await response.json();
                            if (response.status === 200) {
                                if (this.enableReminder) {
                                    // create new reminders for events in serie
                                    let remindAt = this.tempStartDateTime;
                                    remindAt = this.getRemindAt(remindAt);
                                    let reminderRes = await this.createNewReminder({
                                        accounts: this.tempAccounts,
                                        calendarEventId: this.tempUpdatedEvent._id,
                                        remindAt,
                                        fullDay: this.tempTimed,
                                    });
                                }
                                this.showSnackbar({ message: 'Änderungen gespeichert!'});
                                this.showNewCalendarEvent = false;
                                const monthOfCreated = finalStartDateTime.substring(0,7);
                                this.monthsToUpdate.push(monthOfCreated);
                                if (finalStartDateTime !== finalEndDateTime) {
                                    // if event ends in another month update that one as well
                                    const endMonthOfDeleted = finalEndDateTime.substring(0,7);
                                    this.monthsToUpdate.push(endMonthOfDeleted);
                                }
                            } else {
                                this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                            }
                        }
                    } else {
                        // save new event
                        if (this.tempRepeat === 'nicht wiederholen') {
                            // non-repeating event
                            let data = {
                                name: this.tempName,
                                start: finalStartDateTime,
                                end: finalEndDateTime,
                                color: this.tempColor,
                                timed: this.tempTimed,
                                link: this.tempLink,
                                note: this.tempNote,
                                accounts: this.filterTempAccounts(this.tempAccounts),
                                accountsViewable: this.tempAccountsViewable,
                                newTo: this.filterTempAccounts(this.tempAccounts),
                                createdBy: this.currentAccount._id,
                                repeat: this.tempRepeat,
                                repeatUntil: this.tempRepeatUntil,
                                reminder: this.tempRemind,
                            }

                            if (this.createRoom) {
                                data.room = this.tempRoomName;
                            }

                            if (this.createBBB) {
                                // create BBB room
                                let bbbData = {
                                    name: this.tempRoomName,
                                    isOpen: false,
                                    visibleInList: false,
                                };
                                const response = await backend.postExternalBbbRoom(bbbData);
                                const postedRoom = await response.json();
                                data.bbbRoom = postedRoom._id;
                            }

                            let response = await this.createNewCalendarEvent({files: this.newFiles, data});

                            if (response.status === 201) {
                                if (this.enableReminder) {
                                    let remindAt = this.tempStartDateTime;
                                    remindAt = this.getRemindAt(remindAt);
                                    let reminderRes = await this.createNewReminder({
                                        accounts: this.tempAccounts,
                                        calendarEventId: response._id,
                                        remindAt,
                                        fullDay: this.tempTimed,
                                    });
                                }
                                this.showSnackbar({ message: 'Gespeichert!'});
                                this.showNewCalendarEvent = false;
                                const monthOfCreated = finalStartDateTime.substring(0,7);
                                this.monthsToUpdate.push(monthOfCreated);
                                if (finalStartDateTime !== finalEndDateTime) {
                                    // if event ends in another month update that one as well
                                    const endMonthOfDeleted = finalEndDateTime.substring(0,7);
                                    this.monthsToUpdate.push(endMonthOfDeleted);
                                }
                            } else {
                                this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                            }
                        } else {
                            // repeating event
                            if (this.tempRepeat === 'wöchentlich') {
                                await this.createWeekly(finalStartDateTime, finalEndDateTime);
                            } else {
                                await this.createMonthly(finalStartDateTime, finalEndDateTime);
                            }

                            let response = await this.createNewRepeatingEvent({files: this.newFiles, events: this.tempLinkedEvents});

                            if (response.status === 201) {
                                if (this.enableReminder) {
                                    await Promise.all(response.map(async (event) => {
                                        let remindAt = new Date(event.start);
                                        remindAt = this.getRemindAt(remindAt);
                                        let reminderRes = await this.createNewReminder({
                                            accounts: this.tempAccounts,
                                            calendarEventId: event._id,
                                            remindAt,
                                            fullDay: this.tempTimed,
                                        });
                                    }));
                                }
                                this.tempLinkedEvents = [];
                                this.tempRepeatUntil = '';
                                this.selectedUsers = [];

                                this.showSnackbar({ message: 'Gespeichert!'});
                                this.showNewCalendarEvent = false;
                            } else {
                                this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                            }
                        }
                    }
                    await Promise.all(this.monthsToUpdate.map(async (el) => {
                        this.allEvents[el] = await this.getCalendarEventsForMonth(el);
                    }));
                    this.monthsToUpdate = [];
                    this.updateEvents();
                    this.tempAccounts = [];
                    this.tempAccountsViewable = true;
                    this.editRepeat = false;
                    this.showRepeatDialog = false
                    this.newFiles = [];
                    this.deletedFiles = [];
                    this.tempRoomName = '';
                    this.addRoom = false;
                    this.selectedOpen = false;
                    this.selectedElement = null;
                    this.selectedUsers = [];
                    this.selectedEvent = this.tempUpdatedEvent;
                    this.showSaveNewDialog = false;
                }
            } catch (e) {
                console.error(e);
            }
            this.showLoadingOverlay = false;
        },
        validateInput() {
            if (this.tempName === '') {
                this.showSnackbar({ message: 'Bitte geben Sie einen Titel ein!', color: 'error'});
                this.$refs.titleTextfield.focus();
                this.$refs.titleTextfield.color = "error";
                return false;
            } else if (this.tempStartDate === '') {
                this.showSnackbar({ message: 'Bitte wählen Sie ein Startdatum aus!', color: 'error'});
                return false;
            } else if (this.tempStartTime === '' && !this.tempTimed) {
                this.showSnackbar({ message: 'Bitte wählen Sie eine Startzeit aus!', color: 'error'});
                return false;
            } else if (this.tempEndDate === '') {
                this.showSnackbar({ message: 'Bitte wählen Sie ein Enddatum aus!', color: 'error'});
                return false;
            } else if (this.tempEndTime === '' && !this.tempTimed) {
                this.showSnackbar({ message: 'Bitte wählen Sie eine Endzeit aus!', color: 'error'});
                return false;
            } else if (this.tempRepeat !== 'nicht wiederholen' && this.tempRepeatUntil === '' ||
                this.tempRepeat !== 'nicht wiederholen' && this.tempRepeatUntil === undefined) {
                this.showSnackbar({ message: 'Bitte wählen Sie ein Enddatum für die Wiederholung aus!', color: 'error'});
                this.$refs.repeatUntilTextfield.focus();
                this.$refs.repeatUntilTextfield.color = "error";
                return false;
            } else{
                return true;
            }
        },
        checkChange() {
            const finalStartDateTime = this.tempTimed ? this.tempStartDate : (this.tempStartDate + 'T' + this.tempStartTime);
            const finalEndDateTime = this.tempTimed ? this.tempEndDate : (this.tempEndDate + 'T' + this.tempEndTime);
            const filesEqual = this.fileArrayEqual();
            const accsEqual = this.accountsArrayEqual();
            if (this.tempName === this.selectedEvent.name
                && this.tempColor === this.selectedEvent.color
                && this.tempTimed === this.selectedEvent.timed
                && finalStartDateTime === this.selectedEvent.start
                && finalEndDateTime == this.selectedEvent.end
                && this.tempRepeat === this.selectedEvent.repeat
                && this.tempRepeatUntil === this.selectedEvent.repeatUntil
                && this.tempLink === this.selectedEvent.link
                && this.tempNote === this.selectedEvent.note
                && this.tempRemind === this.selectedEvent.reminder
                && filesEqual === true
                && accsEqual === true
            ){
                return false;
            } else {
                return true;
            }
        },
        fileArrayEqual() {
            let equal = true;
            if (this.tempFiles.length !== 0 && this.selectedEvent.files.length === 0) {
                equal = false;
            } else if (this.deletedFiles.length > 0) {
                equal = false;
            } else if (this.newFiles.length > 0) {
                equal = false;
            } else {
                this.selectedEvent.files.map((el) => {
                    if (this.tempFiles.find((element) => {el.id !== element.id})) {
                        equal = false;
                    }
                });
            }
            return equal;
        },
        accountsArrayEqual() {
            let equal = true;
            if (this.selectedUsers.length !== 0 && this.selectedEvent.accounts.length === 0) {
                equal = false;
            } else {
                this.selectedEvent.accounts.map((el) => {
                    if (this.selectedUsers.find((element) => { element.id === el })) {
                        equal = false;
                    }

                });
            }
            return equal;
        },
        cancelClick () {
            if (this.disableEdit) {
                this.selectedOpen = false;
            } else {
                if (this.showNewCalendarEvent) {
                    if (this.tempName === '') {
                        this.dropNewEvent();
                    } else {
                        this.showSaveNewDialog = true;
                    }
                } else {
                    if (this.checkChange()) {
                        // changes happened -> ask user if he wants to save them
                        this.showSaveNewDialog = true;
                    } else {
                        // no changes -> simply close the menu
                        this.selectedOpen = false;
                    }
                }
            }
            this.addRoom = false;
            this.createBBB = false;
            this.createRoom = false;
            this.tempRoomName = '';
        },
        dropNewEvent() {
            this.events.splice(this.events.length - 1, 1);
            this.selectedOpen = false;
            this.selectedElement = null;
            this.showSaveNewDialog = false;
            this.showNewCalendarEvent = false;
            this.newFiles = [];
            this.showSnackbar({ message: 'Neues Event verworfen!', color: 'error'});
        },
        dropChanges() {
            this.selectedOpen = false;
            this.selectedElement = null;
            this.showSaveNewDialog = false;
            this.showSnackbar({ message: 'Änderungen verworfen!', color: 'error'});
        },
        async createWeekly(finalStartDateTime, finalEndDateTime) {
            this.tempLinkedEvents = [];
            let tempFinalStart = new Date(finalStartDateTime);
            let tempFinalEnd = new Date(finalEndDateTime);
            const tempRepeatUntilDate = new Date(this.tempRepeatUntil);
            let counter = 0;
            while (tempFinalStart.getDate() <= tempRepeatUntilDate.getDate()
                    && tempFinalStart.getMonth() <= tempRepeatUntilDate.getMonth()
                    && tempFinalStart.getFullYear() <= tempRepeatUntilDate.getFullYear()
                || tempFinalStart.getMonth() < tempRepeatUntilDate.getMonth()
                    && tempFinalStart.getFullYear() === tempRepeatUntilDate.getFullYear()
                || tempFinalStart.getFullYear() < tempRepeatUntilDate.getFullYear()
            ) {
                counter++;
                const currentEventStartDateTime = this.tempTimed ? (tempFinalStart.getFullYear() + '-' + ('0' + (tempFinalStart.getMonth() + 1)).slice(-2) + '-' + ('0' + tempFinalStart.getDate()).slice(-2))
                    : (tempFinalStart.getFullYear() + '-' + ('0' + (tempFinalStart.getMonth() + 1)).slice(-2) + '-' + ('0' + tempFinalStart.getDate()).slice(-2)
                        + 'T' + ('0' + tempFinalStart.getHours()).slice(-2) + ":" + ('0' + tempFinalStart.getMinutes()).slice(-2));

                const currentEventEndDateTime = this.tempTimed ? (tempFinalEnd.getFullYear() + '-' + ('0' + (tempFinalEnd.getMonth() + 1)).slice(-2) + '-' + ('0' + tempFinalEnd.getDate()).slice(-2))
                    : (tempFinalEnd.getFullYear() + '-' + ('0' + (tempFinalEnd.getMonth() + 1)).slice(-2) + '-' + ('0' + tempFinalEnd.getDate()).slice(-2)
                        + 'T' + ('0' + tempFinalEnd.getHours()).slice(-2) + ":" + ('0' + tempFinalEnd.getMinutes()).slice(-2));

                let data = {
                    name: this.tempName,
                    start: currentEventStartDateTime,
                    end: currentEventEndDateTime,
                    color: this.tempColor,
                    timed: this.tempTimed,
                    link: this.tempLink,
                    note: this.tempNote,
                    accounts: this.tempAccounts,
                    accountsViewable: this.tempAccountsViewable,
                    newTo: counter === 1 ? this.tempAccounts : [],
                    createdBy: this.currentAccount._id,
                    repeat: this.tempRepeat,
                    repeatUntil: this.tempRepeatUntil,
                    reminder: this.tempRemind,
                }

                if (this.createRoom) {
                    data.room = this.tempRoomName;
                }

                if (this.createBBB) {
                    // create BBB room
                    let bbbData = {
                        name: this.tempRoomName,
                        isOpen: false,
                        visibleInList: false,
                    };
                    const response = await backend.postExternalBbbRoom(bbbData);
                    const postedRoom = await response.json();
                    data.bbbRoom = postedRoom._id;
                }

                this.tempLinkedEvents.push(data);
                const monthOfNew = currentEventStartDateTime.substring(0,7);
                this.monthsToUpdate.push(monthOfNew);
                if (currentEventStartDateTime !== currentEventEndDateTime) {
                    // if event ends in another month update that one as well
                    const endMonthOfDeleted = currentEventEndDateTime.substring(0,7);
                    this.allEvents[endMonthOfDeleted] = await this.getCalendarEventsForMonth(endMonthOfDeleted);
                }

                tempFinalStart.setDate(tempFinalStart.getDate() + 7);
                tempFinalEnd.setDate(tempFinalEnd.getDate() + 7);
            }
        },
        async createMonthly(finalStartDateTime, finalEndDateTime) {
            this.tempLinkedEvents = [];
            let tempFinalStart = new Date(finalStartDateTime);
            let tempFinalEnd = new Date(finalEndDateTime);
            const tempRepeatUntilDate = new Date(this.tempRepeatUntil);
            let counter = 0;
            while (tempFinalStart.getMonth() <= tempRepeatUntilDate.getMonth()
                    && tempFinalStart.getFullYear() <= tempRepeatUntilDate.getFullYear()
                || tempFinalStart.getFullYear() < tempRepeatUntilDate.getFullYear()
            ) {
                counter++;
                const currentEventStartDateTime = this.tempTimed ? (tempFinalStart.getFullYear() + '-' + ('0' + (tempFinalStart.getMonth() + 1)).slice(-2) + '-' + ('0' + tempFinalEnd.getDate()).slice(-2))
                    : (tempFinalStart.getFullYear() + '-' + ('0' + (tempFinalStart.getMonth() + 1)).slice(-2) + '-' + ('0' + tempFinalStart.getDate()).slice(-2)
                        + 'T' + ('0' + tempFinalStart.getHours()).slice(-2) + ":" + ('0' + tempFinalStart.getMinutes()).slice(-2));

                const currentEventEndDateTime = this.tempTimed ? (tempFinalEnd.getFullYear() + '-' + ('0' + (tempFinalEnd.getMonth() + 1)).slice(-2) + '-' + ('0' + tempFinalEnd.getDate()).slice(-2))
                    : (tempFinalEnd.getFullYear() + '-' + ('0' + (tempFinalEnd.getMonth() + 1)).slice(-2) + '-' + ('0' + tempFinalEnd.getDate()).slice(-2)
                        + 'T' + ('0' + tempFinalEnd.getHours()).slice(-2) + ":" + ('0' + tempFinalEnd.getMinutes()).slice(-2));

                let data = {
                    name: this.tempName,
                    start: currentEventStartDateTime,
                    end: currentEventEndDateTime,
                    color: this.tempColor,
                    timed: this.tempTimed,
                    link: this.tempLink,
                    note: this.tempNote,
                    accounts: this.tempAccounts,
                    accountsViewable: this.tempAccountsViewable,
                    newTo: counter === 1 ? this.tempAccounts : [],
                    createdBy: this.currentAccount._id,
                    repeat: this.tempRepeat,
                    repeatUntil: this.tempRepeatUntil,
                    reminder: this.tempRemind,
                }

                if (this.createRoom) {
                    data.room = this.tempRoomName;
                }

                if (this.createBBB) {
                    // create BBB room
                    let bbbData = {
                        name: this.tempRoomName,
                        isOpen: false,
                        visibleInList: false,
                    };
                    const response = await backend.postExternalBbbRoom(bbbData);
                    const postedRoom = await response.json();
                    data.bbbRoom = postedRoom._id;
                }

                this.tempLinkedEvents.push(data);
                const monthOfNew = currentEventStartDateTime.substring(0,7);
                this.monthsToUpdate.push(monthOfNew);
                if (currentEventStartDateTime !== currentEventEndDateTime) {
                    // if event ends in another month update that one as well
                    const endMonthOfDeleted = currentEventEndDateTime.substring(0,7);
                    this.allEvents[endMonthOfDeleted] = await this.getCalendarEventsForMonth(endMonthOfDeleted);
                }

                tempFinalStart.setMonth(tempFinalStart.getMonth() + 1);
                tempFinalEnd.setMonth(tempFinalEnd.getMonth() + 1);
            }
        },
        createICal(){
            const ics = require('ics');

            const finalStartDateTime = this.tempTimed ? this.tempStartDate : (this.tempStartDate + 'T' + this.tempStartTime);
            const finalEndDateTime = this.tempTimed ? this.tempEndDate : (this.tempEndDate + 'T' + this.tempEndTime);

            let tempFinalStart = new Date(finalStartDateTime);
            let tempFinalEnd = new Date(finalEndDateTime);

            let start = this.tempTimed ? [tempFinalStart.getFullYear(), tempFinalStart.getMonth() + 1, tempFinalStart.getDate()]
             : [tempFinalStart.getFullYear(), tempFinalStart.getMonth() + 1, tempFinalStart.getDate(), tempFinalStart.getHours(), tempFinalStart.getMinutes()];
            let end = this.tempTimed ? [tempFinalEnd.getFullYear(), tempFinalEnd.getMonth() + 1, tempFinalEnd.getDate()]
             : [tempFinalEnd.getFullYear(), tempFinalEnd.getMonth() + 1, tempFinalEnd.getDate(), tempFinalEnd.getHours(), tempFinalEnd.getMinutes()];

            let finalNote = this.tempNote;

            if (this.tempAccountsStrings.length > 0) {

                let allNames = this.tempAccountsStrings;
                finalNote = finalNote.concat('\n\nTeilnehmer: ', allNames[0]);
                allNames.splice(0,1);
                allNames.map((name) => {
                    finalNote = finalNote.concat(', ', name);
                });
            }

            let event = {};
            const tempRepeatUntilDate = new Date(this.tempRepeatUntil);
            if (this.tempRepeat === 'wöchentlich') {
                const weekdays = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
                const weekday = tempRepeatUntilDate.getDay();
                let rrule = 'FREQ=WEEKLY;BYDAY=' + weekdays[weekday] + ';INTERVAL=1;UNTIL=' + tempRepeatUntilDate.toISOString().replace(/-|:|(.000)/g, '');
                event = {
                    start: start,
                    end: end,
                    title: this.tempName,
                    description: finalNote,
                    url: this.tempLink,
                    recurrenceRule: rrule,
                }
            } else if (this.tempRepeat === 'monatlich') {
                let rrule = 'FREQ=MONTHLY;BYMONTHDAY=' + tempFinalStart.getDate() + ';INTERVAL=1;UNTIL=' + tempRepeatUntilDate.toISOString().replace(/-|:|(.000)/g, '');
                event = {
                    start: start,
                    end: end,
                    title: this.tempName,
                    description: finalNote,
                    url: this.tempLink,
                    recurrenceRule: rrule,
                }
            } else {
                event = {
                    start: start,
                    end: end,
                    title: this.tempName,
                    description: finalNote,
                    url: this.tempLink,
                }
            }

            ics.createEvent(event, (error, value) => {
                if (error) {
                    console.error(error);
                    return;
                }

                var uri = 'data:text/calendar;charset=utf-8,' + value;

                var downloadLink = document.createElement("a");
                downloadLink.href = uri;
                downloadLink.download = this.tempName;

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            });
        },
        openEmail() {
            let emails = this.emailAdresses;
            this.emailAdresses = '';
            let dateToDisplay;
            if (this.tempTimed) {
                dateToDisplay = this.tempStartDateString;
            } else {
                dateToDisplay = `${this.tempStartDateString}, um ${('0' + this.tempStartDateTime.getHours()).slice(-2)}:${('0' + this.tempStartDateTime.getMinutes()).slice(-2)} Uhr`;
            }

            let subject = `Termin: ${dateToDisplay}, ${this.tempName}`;
            const domain = `${location.protocol}//${location.hostname}${(location.port ? ':' + location.port : '')}`;
            this.linkToBBB = `${domain}/join-external/${this.tempBBBRoom._id}?token=${this.tempBBBRoom.token}`;
            let emailBody = `${this.selectedEventCreatedBy.accountName} hat das Event "${this.tempName}" erstellt und möchte es mit dir teilen.
            %0D%0AEs findet am ${dateToDisplay} statt.
            ${this.tempRoomName !== '' ? `%0D%0ARaum: ${this.tempRoomName} ${this.tempBBBRoom ? `-> Meeting-Link: ${this.linkToBBB}` : ''}` : ''}
            ${this.tempLink !== '' ? `%0D%0ALink: ${this.tempLink}` : ''}
            ${this.tempNote !== '' ? `%0D%0ANotiz: ${this.tempNote}` : ''}
            %0D%0AAnbei findest du eine Datei, um das Event direkt in deinen Kalender zu übernehmen.`
            window.location.href = 'mailto:' + emails + '?subject=' + subject + '&body=' + emailBody;
            this.showEmailDialog = false;
            this.linkToBBB = '';
        },
        async loadFullscreenPreview(el) {
            // Files which are suited for the FilePreviewDialog
            this.previewFiles = this.tempFiles.filter((file) => {
                return file['mimetype'].split('/')[0] !== 'video';
            })

            const currentFileInfo = this.selectedEvent.fileInfo.find((info) => info._id === el._id );
            if (!currentFileInfo) {
                console.error('No fileInfo for fileId of file to open found!');
                return;
            }
            const thumbnail = await this.getCalendarFileThumbnail({ fileId: el._id, fileAccessToken: currentFileInfo.accessToken, calendarId: this.selectedEvent._id});
            el.thumbnail = window.URL.createObjectURL(thumbnail);
            el.hasThumbnail = el.thumbnail.type !== 'text/plain; charset=utf-8';
            this.previewSelection = el;
            this.previewInformation = currentFileInfo;
            for (let i = 0; i < this.tempFiles.length; i++) {
                if (el === this.tempFiles[i]) {
                    this.previewSelectionIndex = i;
                }
            }
            this.enableFilePreview = true;
            setTimeout(() => {
                this.$refs.previewDialog.$el.focus();
            }, 150);
        },
        cyclePreview(direction) {
            this.previewSelectionIndex += direction;
            if (this.previewSelectionIndex < 0) {
                this.previewSelectionIndex = this.previewFiles.length - 1;
            } else
                if (this.previewSelectionIndex >= this.previewFiles.length) {
                    this.previewSelectionIndex = 0;
                }
            this.previewSelection = this.previewFiles[this.previewSelectionIndex];
            if (this.previewSelection.type !== 'file' &&
                this.previewSelection.type) {
                this.cyclePreview(direction);
                return;
            }
        },
        resetFilePreview() {
            this.previewSelection = { name: 'Vorschau' };
            this.previewSelectionIndex = 0;
            this.enableFilePreview = false;
        },
        setTypes(type) {
            if (type === 'day') {
                this.calendarType = 'day';
                this.focusDatePickerMode = 'date';
            } else if (type === 'week') {
                this.calendarType = 'week';
            } else {
                this.calendarType = 'month';
                this.focusDatePickerMode = 'month';
            }
        },
        addRoomClick() {
            this.createRoom = true;
            this.createBBB = false;
        },
        addBBBClick() {
            this.createRoom = false;
            this.createBBB = true;
            this.tempRoomName = this.tempName;
        },
        async joinBBBRoom() {
            let res;
            if (this.accountRole === 'parent') {
                res = await backend.getExternalBbbRoomJoinAsModeratorUrl(
                    this.tempBBBRoom._id,
                    this.currentChild._id,
                );
            } else {
                res = await backend.getExternalBbbRoomJoinAsModeratorUrl(
                    this.tempBBBRoom._id
                );
            }
            this.toggleLoading(true);
            const {redirectUrl} = await res.json();
            if (res.status === 409) {
                this.showSnackbar({message: "Aktuell ist der Raum geschlossen!", color: "error"});
                this.toggleLoading(false);
                return;
            }

            if (this.videoTutorial) {
                // Open video tutorial and after try to exec joinClassroom again.
                this.showVideoTutorial = true;
                this.videoTutorialOnCloseJoinExternalRoom = this.tempBBBRoom;
                return;
            }
            window.location.href = redirectUrl;
            this.toggleLoading(false);

        },
        async clickToggleIsOpen() {
            await this.patchExternalBbbRoom(this.tempBBBRoom._id,
                {
                    ...this.tempBBBRoom,
                    isOpen: !this.tempBBBRoom.isOpen,
                }
            );
            await this.loadBBBRoom();
        },
        async patchExternalBbbRoom(externalBbbRoomId, externalBbbRoom = {}) {
            const response = await backend.patchExternalBbbRoom(externalBbbRoomId, externalBbbRoom);
            //const patchedRoom = await response.json();
        },
        async loadBBBRoom() {
            const response = await backend.getExternalBbbRoomById(this.selectedEvent.bbbRoom);
            this.tempBBBRoom = await response.json();
            this.bbbLocked = !this.tempBBBRoom.isOpen;
        },
        async joinBBBGuest() {
            const domain = `${location.protocol}//${location.hostname}${(location.port ? ':' + location.port : '')}`;
            this.linkToBBB = `${domain}/join-external/${this.tempBBBRoom._id}?token=${this.tempBBBRoom.token}`;
            window.open(this.linkToBBB, '_blank').focus();
        },
        async checkBBBChange() {
            if (this.createBBB && this.selectedEvent.bbbRoom) {
                // bbb room already exists
                if (this.tempBBBRoom.name !== this.tempRoomName) {
                    // room name changed
                    if (this.selectedEvent.repeat === 'nicht wiederholen'){
                        const updatedRoom = await this.patchExternalBbbRoom(this.tempBBBRoom._id,
                            {
                                ...this.tempBBBRoom,
                                name: this.tempRoomName,
                            }
                        );
                        return {change: 'roomNameChange', room: updatedRoom};
                    } else {
                        const eventsFromSerie = await this.getCalendarEventsFromSerie(this.selectedEvent.serie);
                        let updatedRooms;
                        eventsFromSerie.map(async (el) => {
                            updatedRooms = await this.patchExternalBbbRoom(el.bbbRoom,
                                {
                                    ...this.tempBBBRoom,
                                    name: this.tempRoomName,
                                }
                            );
                        });
                        return {change: 'repeatNameChange', room: updatedRooms};
                    }
                } else {
                    // room exists and name is the same -> nothing to do
                    return {change: 'noChange'};
                }
            } else if (this.createBBB && !this.selectedEvent.bbbRoom) {
                // bbb room doesn't exist yet and needs to be created
                if (this.selectedEvent.repeat === 'nicht wiederholen'){
                    let bbbData = {
                        name: this.tempRoomName,
                        isOpen: false,
                        visibleInList: false,
                    };
                    const response = await backend.postExternalBbbRoom(bbbData);
                    const postedRoom = await response.json();
                    return {change: 'roomCreated', room: postedRoom};
                } else {
                    const eventsFromSerie = await this.getCalendarEventsFromSerie(this.selectedEvent.serie);
                    let createdRooms;
                    let bbbData = {
                        name: this.tempRoomName,
                        isOpen: false,
                        visibleInList: false,
                    };
                    eventsFromSerie.map(async (el) => {
                        const bbbRes = await backend.postExternalBbbRoom(bbbData);
                        const postedRoom = await bbbRes.json();
                        await this.editCalendarEvent({data: {_id: el._id, bbbRoom: postedRoom._id, room: undefined}, newFiles: this.newFiles, deletedFiles: this.deletedFiles});
                        createdRooms.push(postedRoom);
                    });
                    return {change: 'repeatRoomsCreated', room: createdRooms};
                }
            } else if (!this.createBBB && this.selectedEvent.bbbRoom) {
                // bbb room exists, but should be deleted
                await backend.deleteExternalBbbRoom(this.tempBBBRoom._id);
                return {change: 'roomDeleted', room: this.tempBBBRoom._id};
            } else {
                // no room exists and no need to create one
                return {change: 'noChange'}
            }
        },
        changeFocus(change) {
            if (this.focusDatePickerMode === 'month') {
                const finalString = change + '-1';
                this.focus = finalString;
            } else {
                this.focus = change;
            }
            this.changeTitle();
        },
        async calendarBadgeClick(event) {
            let date;
            this.badgeClicked = true;

            // checks if month has already been loaded
            if(!this.allEvents[event.start.substring(0, 7)]) {
                const res = await this.getCalendarEventsForMonth(event.start.substring(0, 7));
                const adjustedEvents = res.map((event) => {
                    if (event.newTo.includes(this.currentAccount._id)) {
                        event.originalColor = event.color;
                        event.originalName = event.name;
                        event.name = `*NEU* ${event.originalName}`;
                        event.color = '#dd0718';
                    }
                    return event;
                });
                this.allEvents[event.start.substring(0, 7)] = adjustedEvents;
                this.updateEvents();
                this.prevMonth = event.start.substring(5, 7);
            }

            // if time exists, scroll to time
            if (this.calendarType !== 'month' && event.start.includes("T")) {
                this.scrollToTime(event.start.split("T")[1]);
            }
            // prepare date for changeFocus
            switch (this.calendarType) {
                case 'day':
                    date = event.start;
                    break;
                case 'week':
                    date = event.start.substring(0, 10);
                    break;
                case 'month':
                    date = event.start.substring(0, 7);
                    break;
            }

            // change color of event to show which is new
            let intervalId;
            const originalColor = this.events.find(el => el._id === event._id)?.color || ''; // Get the original color

            const toggleColor = () => {
            this.events = this.events.map(el =>
                el._id === event._id ? { ...el, color: el.color === 'success' ? originalColor : 'success' } : el
            );
            };

            this.changeFocus(date);

            toggleColor(); // Set the initial color
            intervalId = setInterval(toggleColor, 700); // Toggle the color every 700 milliseconds

            // Stop the blinking after a certain duration (e.g., 5 seconds)
            setTimeout(() => {
            clearInterval(intervalId); // Clear the interval to stop blinking
            // Restore the original color
            this.events = this.events.map(el =>
                el._id === event._id ? { ...el, color: originalColor } : el
            );
            }, 3500);
        },
        scrollToTime(time) {
            const calendar = this.$refs.calendar;
            calendar.scrollToTime(time);
        },
        updateEvents() {
            const keys = Object.keys(this.allEvents);
            this.events = [];
            keys.map((el) => {
                this.events = this.events.concat(this.allEvents[el]);
            });
        },
        async playVideo(file) {
            if (file.subtitle) {
                const subtitle = await backend.getSubtitle(file.subtitle);
                this.subtitleURL = window.URL.createObjectURL(await subtitle.blob());
            }

            const res = await backend.getCalendarUpload(this.selectedEvent._id, file._id);

            if (res.status === 200) {
                this.videoUrl = window.URL.createObjectURL(await res.blob());
            } else if (res.status === 404) {
                this.showSnackbar({ message: 'Fehler! Diese Datei konnte nicht gefunden werden.', color: 'error'});
            } else {
                this.showSnackbar({ message: 'Ein unerwarteter Fehler ist aufgetreten. Versuchen Sie die Seite neu zu laden.', color: 'error'});
            }
        },
        getRemindAt(remindAt) {
            if (this.tempRemind === '1 Stunde davor') {
                remindAt.setHours(remindAt.getHours() - 1);
            } else if (this.tempRemind === '1 Tag davor') {
                remindAt.setDate(remindAt.getDate() - 1);
            } else if (this.tempRemind === '2 Tage davor') {
                remindAt.setDate(remindAt.getDate() - 2);
            } else if (this.tempRemind === '1 Woche davor') {
                remindAt.setDate(remindAt.getDate() - 7);
            }
            return remindAt;
        },

        async readOutCalendarEntry(event) {
            let dateAndTime = new Date(this.tempStartDateTime);
            let textToRead;

            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};

            if (this.tempTimed) {
                dateAndTime = new Date(this.tempStartDateTime);
                textToRead = `${this.tempName}, findet am ${dateAndTime.toLocaleDateString('de-DE', options)} statt.`;
            } else {
                dateAndTime = new Date(this.tempStartDateTime);
                textToRead = `${this.tempName}, beginnt am ${dateAndTime.toLocaleString('de-DE', options)}.`;
            }
            await this.readText(textToRead, '');
        },

        resetSpeakerIcons() {
            this.usedSpeakerIcon = this.lautsprecherIcon;
            this.usedSpeakerIconNote = this.lautsprecherIcon;

            const iconElements = document.querySelectorAll('.speaker');
            iconElements.forEach(iconElement => {
                if (iconElement.getAttribute('src') === this.lautsprecherAusIcon) {
                    iconElement.setAttribute('src', this.lautsprecherIcon);
                }
            });
        },

        async readText(item, usage) {
            this.resetSpeakerIcons();
            let txt;
            let text;
            if (item === 'Invalid Date') {
                text = 'Aufgaben aus Dateien';
            } else {
                text = this.sanitizeHtml(this.urlify(item));
            }

            if (text) {
                let tmp = document.createElement("DIV");
                tmp.innerHTML = text;
                txt = tmp.textContent || tmp.innerText || "";
            } else {
                txt = 'Dieser Eintrag hat keinen Inhalt.';
            }

            if (this.targetLang !== 'de') {
                txt = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: txt
                });

                this.setTranslatedText(txt);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            await textToSpeech(txt, this.targetLang, () => {
                if (usage === 'note') {
                    this.usedSpeakerIconNote = this.lautsprecherAusIcon;
                } else if (usage.startsWith('fileSpeakerIcon')) {
                    document.getElementById(usage).src = this.lautsprecherAusIcon;
                } else {
                    this.usedSpeakerIcon = this.lautsprecherAusIcon;
                }
            }, () => {
                if (usage === 'note') {
                    this.usedSpeakerIconNote = this.lautsprecherIcon;
                } else if (usage.startsWith('fileSpeakerIcon')) {
                    document.getElementById(usage).src = this.lautsprecherIcon;
                } else {
                    this.usedSpeakerIcon = this.lautsprecherIcon;
                }
            });
        },

        async hasUserNewEvents() {
            this.showCalendarBadge = false;
            this.calendarBadgeEventList = await this.getUnseenEventsForUser();
            if (this.calendarBadgeEventList.length > 0) {
                this.showCalendarBadge = true;
            }
        },

        async clearBadges() {
            const ids = this.calendarBadgeEventList.map((el) => el._id);
            this.setCalenderEventSeen(ids);
            ids.forEach((id) => this.events = this.events.map(el => el._id === id? {...el, name: el.originalName, color: el.originalColor} : el));
            this.calendarBadgeEventList = [];
        },
    },
}
</script>
<style>
.calendarStyle .v-btn__content {
    font-weight: bold;
}
.calendarStyle .v-calendar-weekly__head{
    font-weight: bold;
}
.calendarStyle .v-calendar-daily__head{
    font-weight: bold;
}
</style>
<style lang="scss" scoped>
    .outsideBox{
        position: relative;
    }

    .iconBox{
        position: absolute;
        right: 5px;
        top: -10px;
    }

    .iconToDarkBlue {
        filter:
            brightness(0)
            saturate(100%)
            invert(15%)
            sepia(34%)
            saturate(669%)
            hue-rotate(187deg)
            brightness(91%)
            contrast(89%)
        ;
    }

    .iconToGreen {
        filter:
            brightness(0)
            saturate(100%)
            invert(49%)
            sepia(12%)
            saturate(2947%)
            hue-rotate(68deg)
            brightness(99%)
            contrast(88%)
        ;
    }

    .clickable:hover {
        cursor: pointer;
    }

    .calendarStyle {
        $calendar-weekly-day-label-size: 25px !important;
    }

    .optionBtn {
        border-radius: 8px;
        min-width: 35px !important;
        height: 35px !important;
    }
</style>
