<template>
    <v-dialog
        :value="showDialog"
        width="400"
        height="80%"
        min-height="80%"
        style="overflow-y: hidden !important;"
        @input="closeDialog"
    >
        <v-card
            class="card-style"
            style="overflow: hidden; border-radius: 16px"
        >
            <v-card-title class="chat-header d-flex align-center justify-space-between">
                <div class="d-flex align-center">
                    <img
                        :src="chatIcon"
                        class="icon20 iconToWhite mr-2 mb-1"
                    >
                    <p>Privatchat erstellen</p>
                </div>
                <v-btn
                    x-small
                    text
                    elevation="0"
                    class="pa-0"
                    @click="closeDialog"
                >
                    <img
                        :src="cancelIcon"
                        class="icon20 iconToWhite"
                        alt="schließen"
                    >
                </v-btn>
            </v-card-title>
            <div class="d-flex justify-space-around align-center pt-2 px-2">
                <v-text-field
                    v-model="searchSelectableAccountSet"
                    filled
                    dense
                    hide-details
                    placeholder="Suchen"
                    class="mr-5 "
                    style="max-width: 80%;"
                >
                    <template v-slot:prepend-inner>
                        <img
                            :src="suchIcon"
                            class="icon20"
                            alt=""
                        >
                    </template>
                </v-text-field>
            
                <v-btn
                    id="eK-button-disabled"
                    color="gruen"
                    class="text-none"
                    :disabled="privateRoomPossible()"
                    @click="startPrivateRoom"
                >
                    <span style="color:white">Erstellen</span>
                </v-btn>
            </div>
            <div
                v-if="accountRole !== 'pupil'" 
                class="d-flex justify-start align-center ml-2 py-2"
            >
                <div class="d-flex justify-start ">
                    Filtern nach:
                </div>
                <v-radio-group
                    v-model="selectedFilter"
                    row
                    class="d-flex justify-start mt-0"
                    hide-details
                >
                    <v-radio
                        :value="'all'"
                        label="Alle"
                    />
                    <v-radio
                        :value="'pupil'"
                        label="Schüler"
                    />
                    <v-radio
                        :value="'teacher'"
                        label="Lehrer"
                    />
                    <v-radio
                        v-if="['teacher', 'maintainer'].includes(accountRole)"
                        :value="'parent'"
                        label="Eltern"
                    />
                </v-radio-group>
            </div>
            <v-card-text class="mb-5">
                <v-list
                    data-simplebar
                    :class="accountRole==='pupil' ? 'pupil-scroll-area' : 'scroll-area'"
                >
                    <v-list-item-group
                        v-model="selectedPrivateChatAccountSetId"
                        active-class=""
                    >
                        <v-list-item
                            v-for="item in filteredSelectableAccountSets"
                            :key="item._id"
                            :value="item._id"
                        >
                            <template v-slot:default="{ active }">
                                <v-list-item-action>
                                    <v-checkbox :input-value="active" />
                                </v-list-item-action>
                                <v-list-item-content>
                                    {{ item.displayName }}
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import * as backend from "@/api/backend";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import vueCustomScrollbar from "vue-custom-scrollbar";

import suchIcon from "@/assets/Icons/suche-26.svg";
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import chatIcon from '@/assets/Icons/FaceliftIcons/chat_spechblase.svg';

export default {
    name:"ChatRoomCreatePrivateChatDialog",
    components: {
    },
    data: () => ({
        showDialog: true,
        selectableAccountSets: [],
        selectedPrivateChatAccountSetId: "",
        suchIcon,
        cancelIcon,
        chatIcon,
        searchSelectableAccountSet: '',
        selectedFilter: 'all',
    }),
    computed: {
        ...mapState("tooltips", ["showTooltips"]),
        ...mapGetters("auth", ["accountRole"]),
        ...mapGetters("accountSets", ["accountSets"]),
        ...mapGetters("accounts", ["accountsById"]),
        ...mapState("auth", ["account"]),
        ...mapGetters("groups", ["groups","groupLeadersByGroupId"]),
        ...mapGetters("teachers", ["teachersById"]),
        ...mapGetters("chat", ["chatRooms"]),
        myAccountSet() {
            return this.accountSets.find(
                (accountSet) =>
                    accountSet.type === "account" &&
                    accountSet.account === this.account._id
            )},
        filteredAccountSets() {
            return this.selectableAccountSets.filter((accountSet) => accountSet.displayName.toLowerCase().includes(this.searchSelectableAccountSet.toLowerCase()));
        },
        filteredSelectableAccountSets(){
            if(this.selectedFilter === 'pupil'){
                return this.filteredAccountSets.filter((accountSet) => this.accountsById[accountSet.account].role === 'pupil');
            }else if(this.selectedFilter === 'teacher'){
                return this.filteredAccountSets.filter((accountSet) => this.accountsById[accountSet.account].role === 'teacher');
            }
            else if(this.selectedFilter === 'parent'){
                return this.filteredAccountSets.filter((accountSet) => this.accountsById[accountSet.account].role === 'parent');

            }else{
                return this.filteredAccountSets;
            }
        },
    },
    async mounted() {
        await this.getSelectableAccountSets();
    },
    methods: {
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapActions("teachers", ["getClassTeachers","getAllGroupTeachers"]),
        ...mapActions("chat", ["postChatRoom"]),
        ...mapActions("parents", ["getParents"]),
        ...mapActions("pupils", ["getPupil"]),

        privateRoomPossible() {
            return this.selectedPrivateChatAccountSetId === '' || this.selectedPrivateChatAccountSetId === undefined
        },
        async startPrivateRoom() {
            // if (this.selectedPrivateChatAccountSetId) {
            // if (!this.chatIds.includes(this.selectedPrivateChatAccountSetId)) {
            /**
             * Checking for duplicate chats: The chatRooms variable from the store gives us an array of our current
             * chat rooms. Private chats only have two members: yourself and the other person. So all we have to do
             * is to compare the accountSet attributes with our chosen user from the v-autocomplete. There should be
             * a more efficient way to check this though since a triple nested for loop has a very high complexity.
             */
            let duplicateChat = false;
            for (let i = 0; i < this.chatRooms.length && !duplicateChat; i++) {
                // Skip this iteration if it's not a private room (important)
                if (this.chatRooms[i].type === 'privateChat') {
                    for (let j = 0; j < this.chatRooms[i].memberships.length && !duplicateChat; j++) {
                        for (let k = 0; k < this.chatRooms[i].memberships[j].accountSet.length && !duplicateChat; k++) {
                            // Skip this iteration if it's your own ID being checked
                            if (this.chatRooms[i].memberships[j].accountSet !== this.myAccountSet._id) {
                                if (this.chatRooms[i].memberships[j].accountSet === this.selectedPrivateChatAccountSetId) {
                                    duplicateChat = true;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            if (!duplicateChat) {
                const otherMembership = {
                    accountSet: this.selectedPrivateChatAccountSetId,
                    role: "member",
                };
                const myMembership = {
                    accountSet: this.myAccountSet._id,
                    role: "member",
                };
                await this.postChatRoom({
                    memberships: [otherMembership, myMembership],
                    type: "privateChat",
                });
            } else {
                this.showSnackbar({ message: 'Sie haben bereits einen Chat mit diesem Nutzer!', color: 'error'});
            }
            this.selectedPrivateChatAccountSetId = "";
            // } else {
            // this.showSnackbar({
            //   message: "Sie haben schon einen Chat mit diesem Nutzer",
            // });
            // }
            // }
            this.closeDialog();
        },
        closeDialog() {
            this.$emit("closeDialog");
        },
        async getSelectableAccountSets() {
            const getAccountSetDisplayName = (accountSet) => {
                if (accountSet.type === "account") {
                    const account = this.accountsById[accountSet.account];
                    return account ? account.displayName : account._id;
                }
            };

            this.selectableAccountSets = this.accountSets
                .filter((accountSet)=> accountSet.type === "account")
                .filter((accountSet) => accountSet._id !== this.myAccountSet?._id)
                .map((accountSet) => ({
                    ...accountSet,
                    displayName: getAccountSetDisplayName(accountSet),
                }))
                .filter((accountSet) => !accountSet.displayName.includes("PersonAlle"));

            // Filter out teachers who are not your class teacher
            if (this.accountRole === 'pupil' || this.accountRole === 'parent') {
                let accountId = this.account._id;
                let classTeachers = [];
                let teachersFromPupil = [];
                // const pupilGroups = this.groups.filter(group=> group.participant.includes())
                if (this.accountRole === 'parent') {
                    const parentAccs = await this.getParents(true);
                    const parentAcc = parentAccs.find((parent) => parent.account === accountId);
                    for (let i = 0; i < parentAcc.pupils.length; i++ ) {
                        const pupil = await this.getPupil(parentAcc.pupils[i]);
                        // groups from pupilAccount, then leaderIds --> dont need backend request 
                        const teachers = this.accountsById[pupil.account]
                            .groups.flatMap(groupId => this.groupLeadersByGroupId[groupId]);
                        const allgroupTeachers = await this.getAllGroupTeachers(pupil.account);
                        teachersFromPupil = teachersFromPupil.concat(allgroupTeachers);
                        classTeachers = [...classTeachers, ...teachers];
                    }
                } else {
                    classTeachers = this.accountsById[accountId]
                            .groups.flatMap(groupId => this.groupLeadersByGroupId[groupId]);
                        const allgroupTeachers = await this.getAllGroupTeachers(accountId);
                        teachersFromPupil = teachersFromPupil.concat(allgroupTeachers);
                }
                    const uniqueTeacherIds = Array.from(new Set(classTeachers.concat(teachersFromPupil)));
                    const uniqueTeachers = uniqueTeacherIds.map(teacherId =>{ 
                        return this.teachersById[teacherId]});
                    this.selectableAccountSets = this.selectableAccountSets.filter(
                    (set) => {
                        if (this.accountsById[set.account].role === 'teacher') {
                            // Checks if teacher from set is included in classTeachers array
                            return uniqueTeachers.some((teacher) => teacher.account === set.account)
                        } else {
                            // No teacher, but maintainer or parent, so it doesn't need to be compared
                            return true;
                        }
                    });
            }
        }
    }
}
</script>


<style lang="scss" scoped>
.card-style {
    min-height: 80vh; 
	max-height: 80vh; 
    .v-card__text {
        .v-btn {
            border-radius: 8px;
        }
        .v-input__control > .v-input__slot {
            border-radius: 8px;
        }
    }
}

.chat-header {
    background-color: var(--v-chatColor-base);
    border-radius: 15px 15px 0 0;
    font-size: larger;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    color: white;
}


.scroll-area {
    min-height: calc(80vh - 190px); 
	max-height: calc(80vh - 190px); 
	//necessary if we want to disable horizontal scroll
	overflow-x: hidden;
}
.pupil-scroll-area {
    min-height: calc(80vh - 120px); 
	max-height: calc(80vh - 120px); 
	//necessary if we want to disable horizontal scroll
	overflow-x: hidden;
}
.formHeader {
    font-size: 16px;
    margin-top: 40px !important;
    margin-bottom: 20px !important;
}

.icon {
    height: 20px;
}

.iconToGrey {
    filter: brightness(0) saturate(100%) invert(89%) sepia(0%) saturate(0%)
    hue-rotate(326deg) brightness(87%) contrast(88%) !important;
}
#eK-button-disabled.v-btn--disabled {
    opacity: 0.5;
    background-color: var(--v-grau-base) !important;
    color: white !important;
}
</style>
