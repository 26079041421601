<template>
    <div
        style="background-color: #f8f8f8"
        class="backgroundImg"
    >
        <!-- Bottom navigation bar, used only for mobile devices-->
        <div
            v-if="!faq && !hideNavbar"
            class="btmNav"
        >
            <div
                class="d-flex flex-row justify-space-around align-center"
                style="height: 100%; width: 100%"
            >
                <v-btn
                    v-for="item in btmNavItems"
                    :key="item.name"
                    elevation="0"
                    small
                    class="btmNavBtn pa-0"
                    :style="currSlide === item.goToIndex ? 'background-color:' + item.bgColor + '!important;' : ''"
                    @click="switchSlide(item.goToIndex)"
                >
                    <v-badge
                        :content="getBadge(item.goToIndex)"
                        :value="getBadge(item.goToIndex)"
                        color="#ff0000"
                        offset-x="27"
                        style="z-index: 1;"
                    >
                        <v-overlay
                            v-if="!isMounted(item)"
                            color="grey"
                            opacity="0.6"
                            style="border-radius: 8px;position: absolute; cursor: pointer; height: 50px; width: 50px;top: -7px; left: -7px;"
                            z-index="5"
                        />
                        <img
                            v-if="item.icon"
                            :src="item.icon"
                            :alt="item.alt"
                            class="btmNavIcon"
                            :style="currSlide === item.goToIndex ? 'filter: brightness(1000%);' : item.color "
                        >
                        <v-icon
                            v-if="item.mdi"
                            size="xx-large"
                            :style="currSlide === item.goToIndex ? 'color: white' : `color: ${item.bgColor}`"
                        >
                            {{ item.mdi }}
                        </v-icon>
                    </v-badge>
                </v-btn>

                <v-badge
                    v-if="showEmailBadge"
                    content="1"
                    offset-x="-20"
                    offset-y="20"
                    color="#3ba934"
                    class="align-self-start insetBadge"
                    style="z-index: 9"
                />
                <div @click="sidebarMobile = true; sidebar = true;">
                    <Avatar
                        v-if="person"
                        :show-burger="true"
                        :profile="person.account"
                        :alt="'Profil öffnen'"
                    />
                </div>
            </div>
        </div>

        <v-overlay
            v-if="supportPageOpen || sidebar"
            z-index="11"
            overlay-opacity="0.8"
        />
        <!-- filesystemMounted isnt thrown somehow on teacher account, maybe needs to be added later -->
        <v-overlay
            v-if="!hidePreloader && (
                windowWidth < 901 && !allWidgetsMounted ||
                windowWidth > 900 && page === 0 && !page0WidgetsMounted || 
                windowWidth > 900 && page === 1 && !page1WidgetsMounted
            )"
            z-index="50"
            :opacity="1.0"
            color="white"
        >
            <Preloader />
        </v-overlay>

        <!-- Top menu bar-->
        <v-row
            style="background-color: var(--v-headerblue-base); max-height: 74px !important; align-content: center !important; "
            class="displayHide"
        >
            <v-col
                class="d-flex align-center"
                style="margin-left: 2.5%"
            >
                <BBBDropdown />
            </v-col>

            <!--name and options-->
            <v-col
                class="d-flex justify-end align-center pl-0"
                style="margin-right: 2.5%"
            >
                <div class="d-flex justify-center align-center">
                    <p style="color: white">
                        {{ `${this.person ? this.person.name.slice(0,1) + '.' : ''} ${this.person ? this.person.lastName : ''}` }}
                    </p>
                </div>

                <v-tooltip
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-badge
                            :value="showCalendarBadge"
                            left
                            offset-x="10"
                            offset-y="15"
                            color="#ff0000"
                            class="ml-4"
                            :content="eventsNotSeen"
                        >
                            <v-btn
                                v-if="!calendarDeactivated"
                                x-small
                                style="width: 50px; height: 50px; border-radius: 8px; background-color: #FFFFFF"
                                elevation="0"
                                aria-label="Kalender"
                                v-bind="attrs"
                                v-on="on"
                                @click="openCalendar"
                            >
                                <img
                                    :src="kalenderIcon"
                                    alt="Kalender"
                                    class="iconToHeaderblue"
                                    style="max-width: 25px; max-height: 25px;"
                                >
                            </v-btn>
                        </v-badge>
                    </template>
                    <span>Kalender</span>
                </v-tooltip>

                <calendar-app
                    v-if="showCalendarWidget"
                    @close="() => {showCalendarWidget = false; hasUserNewEvents();}"
                />

                <v-tooltip
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-if="!diaryDeactivated"
                            x-small
                            class="ml-4"
                            elevation="0"
                            style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                            v-bind="attrs"
                            v-on="on"
                            @click="openDiary()"
                        >
                            <img
                                :src="bookIcon"
                                alt="Klassenbuch"
                                class="iconToHeaderblue"
                                style="max-width: 30px; max-height: 30px;"
                            >
                        </v-btn>
                    </template>
                    <span>Klassenbuch</span>
                </v-tooltip>

                <v-tooltip
                    v-if="magnifier"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            style="background-color: var(--v-chatColor-base) !important; width: 50px; height: 50px; border-radius: 8px;"
                            x-small
                            elevation="0"
                            class="ml-4"
                            v-on="on"
                            @click="toggleMagnifier();"
                        >
                            <img
                                :src="lupenIcon"
                                style="max-width: 25px; max-height: 25px;"
                                alt="Lupe einschalten/ausschalten (Alt + L)"
                            >
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span>
                            <span style="text-decoration: underline white">L</span>upe einschalten/ausschalten (Alt + L)
                        </span>
                    </div>
                </v-tooltip>

                <v-tooltip
                    v-if="keyboard"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            x-small
                            elevation="0"
                            class="ml-4"
                            style="background-color: var(--v-chatColor-base) !important; width: 50px; height: 50px; border-radius: 8px;"
                            v-on="on"
                            @click="toggleKeyboard()"
                        >
                            <img
                                :src="keyboardIcon"
                                alt="Tastatursteuerung einschalten/ausschalten (Alt + T)"
                                height="25"
                            >
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span>
                            <span style="text-decoration: underline white">T</span>astatursteuerung einschalten/ausschalten (Alt + T)
                        </span>
                    </div>
                </v-tooltip>

                <v-tooltip
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                            x-small
                            elevation="0"
                            class="ml-4 animateIcon"
                            v-bind="attrs"
                            v-on="on"
                            @click="reloadPage()"
                        >
                            <img
                                :src="aktualisierenIcon"
                                alt="Aktualisieren"
                                class="iconToHeaderblue"
                                style="max-width: 25px; max-height: 25px;"
                            >
                        </v-btn>
                    </template>
                    <span>Aktualisieren</span>
                </v-tooltip>

                <v-tooltip
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-ripple
                            x-small
                            elevation="0"
                            class="ml-4 px-0"
                            style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                            v-bind="attrs"
                            v-on="on"
                            @click="() => { setSupportPageOpen(true); }"
                        >
                            <img
                                :src="supportIcon"
                                class="iconToHeaderblue"
                                width="25"
                                alt="Hilfe"
                            >
                        </v-btn>
                    </template>
                    <span>Hilfe</span>
                </v-tooltip>

                <v-badge
                    v-if="showEmailBadge"
                    content="1"
                    overlap
                    left
                    offset-y="20"
                    color="#3ba934"
                    style="z-index: 9; align-base"
                    class="ml-4 align-self-start insetBadge"
                />
                <v-tooltip
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            class="d-flex align-center pointer"
                            :class="showEmailBadge ? '' : 'ml-4'"
                            v-bind="attrs"
                            v-on="on"
                            @click="sidebar = true"
                        >
                            <Avatar
                                v-if="person"
                                :show-burger="true"
                                :profile="person.account"
                                :alt="'Profil öffnen'"
                            />
                        </div>
                    </template>
                    <span>Profil</span>
                </v-tooltip>
            </v-col>
        </v-row>

        <!-- Widgets desktop -->
        <div
            v-if="windowWidth > 900 && !faq"
            class="big mt-4"
            style="justify-content: space-around; align-items: center"
        >
            <v-tooltip
                v-if="!weeklyView && showNextSlide"
                right
            >
                <template v-slot:activator="{ on }">
                    <v-badge
                        :value="isUpdatesOnOtherPage"
                        right
                        offset-x="10"
                        offset-y="15"
                        class="badgeClass"
                        style="z-index: 1"
                        color="#ff0000"
                    >
                        <v-card
                            style="height: 50vh; display: flex"
                            class="buttonFocusHover"
                            @click="changePage(-1)"
                            v-on="on"
                        >
                            <v-btn
                                fab
                                small
                                icon
                                color="#eaeaea"
                                style="width: 2vw"
                                class="align-self-center mx-1 mb-8"
                            >
                                <img
                                    style="position: relative; margin:auto; width: 30px"
                                    alt="Pfeil nach links"
                                    :src="left"
                                    class="imgFocusHover"
                                >
                            </v-btn>
                        </v-card>
                    </v-badge>
                </template>
                <span>Vorherige Seite</span>
            </v-tooltip>
            <div>
                <BadgeExplanations
                    v-if="!weeklyView"
                    :small="false"
                />
                <keep-alive>
                    <PlanWidget
                        v-if="page === 0 && !planDeactivated"
                        :style="weeklyView ? 'width: 94vw;' : '' "
                        @planBadge="isFirstPageUpdated.push('plan')"
                        @clearAll="isFirstPageUpdated = isFirstPageUpdated.filter((el) => el !== 'plan')"
                        @start-bbb-tut="startBBBTut"
                        @audioOverlay="handleAudioUrl"
                        @planMounted="planMounted = true"
                    />
                </keep-alive>
                <keep-alive>
                    <FileWidgetv2
                        v-if="page === 1 && !weeklyView && !filesDeactivated" 
                        @filesystemMounted="filesystemMounted = true"
                    />
                </keep-alive>
            </div>

            <div
                v-if="!weeklyView"
                class="pa-0"
            >
                <keep-alive>
                    <BrettWidget
                        v-if="page === 0 && !brettDeactivated"
                        @brettBadge="isFirstPageUpdated.push('brett')"
                        @clearBrettBadge="isFirstPageUpdated.splice(isFirstPageUpdated.indexOf('brett'),1);"
                        @clearAllBadges="isFirstPageUpdated = isFirstPageUpdated.filter((el) => el !== 'brett')"
                        @brettMounted="brettMounted = true"
                    />
                </keep-alive>
                <keep-alive>
                    <MessagesWidget
                        v-if="page === 1 && !messagesDeactivated"
                        :role="'teacher'"
                        @badge="isSecondPageUpdated.push('parental')"
                        @clearBadge="isSecondPageUpdated.splice(isSecondPageUpdated.indexOf('parental'), 1);"
                        @clearAllBadges="isSecondPageUpdated = isSecondPageUpdated.filter((el) => el !== 'parental')"
                        @messagesMounted="messagesMounted = true"
                    />
                </keep-alive>
            </div>
            <div v-if="!weeklyView">
                <keep-alive>
                    <ChatWidgetV2
                        v-if="page === 0 && !chatDeactivated"
                        @chatBadge="isFirstPageUpdated.push('chat')"
                        @clearAllBadge="isFirstPageUpdated = isFirstPageUpdated.filter((entry) => entry !== 'chat')"
                        @chatMounted="chatMounted = true"
                    />
                </keep-alive>
                <keep-alive>
                    <Verlag v-if="page === 1 && bonusFunctions.etherpad" />
                </keep-alive>
                <keep-alive>
                    <AppWidget
                        v-if="page === 1 && !appsDeactivated"
                        @openDiary="openDiary()"
                        @appwidgetMounted="appwidgetMounted = true"
                    />
                </keep-alive>
            </div>
            <v-tooltip
                v-if="!weeklyView && showNextSlide"
                left
            >
                <template v-slot:activator="{ on }">
                    <v-badge
                        :value="isUpdatesOnOtherPage"
                        left
                        offset-x="10"
                        offset-y="15"
                        class="badgeClass"
                        color="#ff0000"
                    >
                        <v-card
                            style="height: 50vh; display: flex"
                            class="buttonFocusHover"
                            @click="changePage(1)"
                            v-on="on"
                        >
                            <v-btn
                                fab
                                small
                                icon
                                color="#eaeaea"
                                style="width: 2vw;"
                                class="align-self-center mx-1 mb-8"
                            >
                                <img
                                    style="position: relative; margin:auto; width: 30px"
                                    alt="Pfeil nach rechts"
                                    :src="right"
                                    class="imgFocusHover"
                                >
                            </v-btn>
                        </v-card>
                    </v-badge>
                </template>
                <span>Nächste Seite</span>
            </v-tooltip>
        </div>
        <Faq
            v-else-if="windowWidth > 900 && faq"
            style="min-height: auto; height: 90vh; overflow-y: scroll"
        />

        <!-- Widgets mobile -->
        <VueSlickCarousel
            v-else-if="!faq "
            ref="carousel"
            class="small topPadding"
            :style="weeklyView ? 'width: 90vw; margin-left: auto; margin-right: auto;' : ''"
            :arrows="false"
            :initial-slide="initialSlide"
            :infinite="false"
            :dots="false"
            :touch-move="false"
            @afterChange="vueSlickCarouselAfterChange"
        >
            <PlanWidget
                v-if="!planDeactivated"
                :small="true"
                :reset="reloadPlan"
                @planRemounted="reloadPlan = false"
                @start-bbb-tut="startBBBTut"
                @planBadge="isFirstPageUpdated.push('plan')"
                @clearAll="isFirstPageUpdated = isFirstPageUpdated.filter((el) => el !== 'plan')"
                @audioOverlay="handleAudioUrl"
                @planMounted="planMounted = true"
            />
            <BrettWidget
                v-if="!brettDeactivated"
                style="height: 100vh"
                :class="weeklyView ? 'ml-14' : ''"
                :reset="reloadBrett"
                @brettBadge="isFirstPageUpdated.push('brett')"
                @brettRemounted="reloadBrett = false"
                @clearBrettBadge="isFirstPageUpdated.splice(isFirstPageUpdated.indexOf('brett'),1)"
                @clearAllBadges="isFirstPageUpdated = isFirstPageUpdated.filter((el) => el !== 'brett')"
                @brettMounted="brettMounted = true"
            />
            <ChatWidgetV2
                v-if="!chatDeactivated"
                style="height: 100vh"
                class="mt-4"
                :reset="reloadChat"
                @chatRemounted="reloadChat = false"
                @chatBadge="isFirstPageUpdated.push('chat')"
                @clearAllBadge="isFirstPageUpdated = isFirstPageUpdated.filter((entry) => entry !== 'chat')"
                @chatMounted="chatMounted = true"
            />
            <FileWidgetv2
                v-if="!filesDeactivated"
                :reset="reloadFilesystem"
                style="height: 100vh"
                @filesystemRemounted="reloadFilesystem = false" 
                @filesystemMounted="filesystemMounted = true"
            />
            <MessagesWidget
                v-if="!messagesDeactivated"
                :role="'teacher'"
                style="height: 100vh"
                class="mt-4"
                :reset="reloadMessages"
                @messagesRemounted="reloadMessages = false"
                @badge="isSecondPageUpdated.push('parental')"
                @clearBadge="isSecondPageUpdated.splice(isSecondPageUpdated.indexOf('parental'), 1);"
                @clearAllBadges="isSecondPageUpdated = isSecondPageUpdated.filter((el) => el !== 'parental')"
                @messagesMounted="messagesMounted = true"
            />
            <AppWidget
                v-if="!appsDeactivated"
                style="height: 100vh"
                class="mt-4"
                :reset="reloadAppwidget"
                @appWidgetRemounted="reloadAppwidget = false"
                @openDiary="openDiary()"
                @appwidgetMounted="appwidgetMounted = true"
            />
        </VueSlickCarousel>
        <Faq
            v-else-if="faq"
            style="height: 100vh; min-height: 100vh; overflow-y: scroll"
        />
        <Sidebar
            v-if="sidebar"
            v-model="sidebar"
            :mobile="sidebarMobile"
            :sidebar-open="sidebar"
            @closeSidebar="()=>{sidebar = false; sidebarMobile = false;}"
            @openUsageAgreement="revokeUsageAgreement"
            @hideBadge="showEmailBadge = false"
        />


        <v-dialog
            v-model="securityQuestionDialog"
            width="600px"
        >
            <v-card>
                <v-card-title>
                    Sicherheitsfrage festlegen
                </v-card-title>
                <v-card-text>
                    <p class="mb-2">
                        Sie haben noch keine Sicherheitsfrage für Ihr Konto festgelegt.
                        Wählen Sie nun eine Frage und eine Antwort fest um die Sicherheit ihres Kontos zu erhöhen.
                    </p>
                    <p>Sicherheitsfrage auswählen</p>
                    <v-select
                        v-model="securityQuestion"
                        outlined
                        dense
                        hide-details
                        :items="securityQuestions"
                        :menu-props="{ bottom: true, offsetY: true }"
                        no-data-text="Sicherheitsfragen konnten nicht geladen werden"
                        class="mb-2"
                        style="max-width: 600px;"
                    />

                    <div v-if="securityQuestion">
                        <p>Sicherheitsfrage beantworten</p>
                        <v-text-field
                            v-model="securityQuestionAnswer"
                            outlined
                            dense
                            hide-details
                            class="mb-2"
                            style="max-width: 600px;"
                        />
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-end px-6 pb-4">
                    <v-btn
                        id="custom-disabled"
                        :disabled="!(securityQuestion && securityQuestionAnswer)"
                        color="gruen"
                        class="text-none"
                        elevation="0"
                        dark
                        @click="setSecurityQuestion"
                    >
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <VideoTutorial
            v-if="videoTutorial && !videoTutorialClosed"
            :video-tutorial="videoTutorial"
            @close="videoTutorialClosed = true"
        />

        <SlideTutorial
            v-if="!introTutorial || showBBBTutorial"
            :content="showBBBTutorial ? tutorialCollection.bbbTeacher.map(el => el.img) : tutorialCollection.normalTeacher.map(el => el.img)"
            :subtitle-headers-german="showBBBTutorial ? tutorialCollection.bbbTeacher.map(el => el.header) : tutorialCollection.normalTeacher.map(el => el.header)"
            :subtitles-german="showBBBTutorial ? tutorialCollection.bbbTeacher.map(el => el.text) : tutorialCollection.normalTeacher.map(el => el.text)"
            :force-tutorial="false"
            @close="showBBBTutorial ? closeBBBTutorial() : closeIntroTutorial()"
        />
        <keep-alive>
            <Diary
                v-if="diaryDialog"
                :diary-dialog="diaryDialog"
                :teacher="person"
                @close="diaryDialog = false"
            />
        </keep-alive>
        <SupportChat
            v-if="supportPageOpen"
            @resetTutorial="resetTutorial()"
        />
        <ForceLogoutDialog
            :current-account="crtAccount._id"
            :show-force-logout-dialog="showForceLogoutDialog"
            :other-connected-devices="otherConnectedDevices"
            @closeForceLogout="(showSnackbar)=>{if(showSnackbar){ confirmForceLogout();} showForceLogoutDialog = false}"
            @confirmForceLogout="confirmForceLogout"
        />

        <v-overlay
            v-if="audioOverlay"
            opacity="0.8"
            z-index="95"
        >
            <v-icon
                large
                style="position: fixed; top: 50px; right: 50px; z-index: 99"
                dark
                @click="audioOverlay = false"
            >
                fas fa-times
            </v-icon>
            <audio
                id="audioPlayer"
                :src="audioUrl"
                controls
                :width="windowWidth > 900 ? '80%' : '40%'"
                :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }"
            >
                <source
                    :src="audioUrl"
                    type="audio/mpeg"
                >
                Your browser does not support the video tag.
            </audio>
        </v-overlay>
        <Nutzungsbedingungen
            :usage-agreement-not-signed="usageAgreementNotSigned"
            :current-account="crtAccount"
            :revoke="revokeAgreement"
            @closeDialog="()=>{usageAgreementNotSigned = false}"
        />
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import Preloader from "@/components/Utils/Preloader.vue";
import AppWidget from "@/components/Apps/AppWidget";
import Avatar from "@/components/SharedViews/Profile/Avatar";
import BrettWidget from "../BrettWidget";
// import BrettWidget from "../../components/BrettWidget";
// import FileWidget from "../../components/FileWidget";
// import ChatWidget from "../Chat/ChatWidget";
import ChatWidgetV2 from "../ChatV2/ChatWidgetV2";
import MessagesWidget from "../MessagesWidget";
import Verlag from "../../components/Verlag";
import SupportChat from "../Support/SupportChat";
import Diary from "../Lehrer/Diary";
import Faq from "../Faq";
import FileWidgetv2 from "../FileArchive/FileWidgetv2";
import PlanWidget from "../SharedViews/Stundenplan/Schueler_und_Lehrer/PlanWidget";
import Sidebar from "@/components/Sidebar";
import SlideTutorial from "@/components/Tutorial/SlideTutorial";
import VueSlickCarousel from 'vue-slick-carousel';
import VideoTutorial from "../../components/Tutorial/VideoTutorial.vue";
import tutorialCollection from "@/constants/tutorialCollection";
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css' // optional style for arrows & dots

import supportIcon from '@/assets/Icons/FaceliftIcons/hilfe_support_telefon_eklara.svg';
import briefIcon from '../../assets/Icons/letter.svg'
import left from '@/assets/Icons/FaceliftIcons/zurueck_pfeil.svg';
import right from '@/assets/Icons/FaceliftIcons/vor_pfeil.svg';
import stundenplanIcon from "../../assets/Icons/timetable.svg";
import brettIcon from "../../assets/Icons/brett.svg";
import chatIcon from "../../assets/Icons/chat-alternative-filled-102.svg";
import dateienIcon from "../../assets/Icons/file.svg";
import aktualisierenIcon from "../../assets/Icons/FaceliftIcons/aktualisieren.svg";
import kalenderIcon from "../../assets/Icons/FaceliftIcons/kalender_datum_zeit.svg";
import bookIcon from "../../assets/Icons/FaceliftIcons/klassenbuch.svg";
import lupenIcon from '../../assets/Icons/redesignV3/magnifier.svg';
import keyboardIcon from '../../assets/Icons/keyboard-24px.svg';
import BBBDropdown from "@/components/BBBDropdown";
import CalendarApp from "../Calendar/CalendarApp.vue";
import Nutzungsbedingungen from '../Policys/Nutzungsbedingungen.vue';
import BadgeExplanations from "@/components/Utils/BadgeExplanations.vue";
import ForceLogoutDialog from '../Utils/ForceLogoutDialog.vue';


import * as backend from '@/api/backend';
import EventBus from "../../util/eventBus";

export default {
    name: "Plan",
    components: {
        BBBDropdown,
        AppWidget,
        Avatar,
        BrettWidget,
        ChatWidgetV2,
        Diary,
        Faq,
        // FileWidget,
        FileWidgetv2,
        MessagesWidget,
        PlanWidget,
        Sidebar,
        SlideTutorial,
        SupportChat,
        Verlag,
        VueSlickCarousel,
        VideoTutorial,
        CalendarApp,
        Preloader,
        Nutzungsbedingungen,
        BadgeExplanations,
        ForceLogoutDialog,
    },
    data() {
        return {
            hidePreloader: false,
            usageAgreementNotSigned: false,
            revokeAgreement: false,
            left,
            right,
            aktualisierenIcon,
            lupenIcon,
            keyboardIcon,
            supportIcon,
            kalenderIcon,
            bookIcon,

            showEmailBadge: false,
            person: null,
            page: 0,
            initialSlide: 0,

            isSecondPageUpdated: [],
            isFirstPageUpdated: [],

            crtAccount: null,

            securityQuestionSet: true,     // TODO: set to null on default | is a security question set for this account?
            securityQuestionDialog: false,
            securityQuestions: [],          // holds the available security questions
            securityQuestion: null,         // is a security question selected in the dialog
            securityQuestionAnswer: '',     // the answer to the selected security question

            introTutorial: null,
            bbbTutorialCookie: null,
            showBBBTutorial: false,

            showCalendarWidget: false,
            showCalendarBadge: false,
            eventsNotSeen: '',

            diaryDialog: false,
            sidebar: false,         // Opens the sidebar which has all the profile management in it
            sidebarMobile: false,   // If opening sidebar from bottom nav set sidebarMobile true

            callbackMethod: null,   // joinBBB method passed up from Fach-component

            currSlide: 0,               // To color icons of mobile bottom navBar
            slideChangeAllowed: true,   // To prevent spamming of btmNavBar, which would bring highlighting of btmNavIcons and currently displayed widget out of sync

            videoTutorial: false,
            videoTutorialClosed: false,

            tutorialCollection,


            audioOverlay: false,
            audioUrl: null,
                       
            appwidgetMounted: false,
            brettMounted: false,
            chatMounted: false,
            planMounted: false,
            messagesMounted: false,
            filesystemMounted: false,
            otherConnectedDevices: null,
            showForceLogoutDialog: false,

            reloadChat: false,
            reloadBrett: false,
            reloadPlan: false,
            reloadMessages: false,
            reloadFilesystem: false,
            reloadAppwidget: false,
        };
    },
    computed: {
        ...mapState('weeklyView', ["weeklyView"]),
        ...mapState('faq', ['faq']),
        ...mapState('auth', ['stayLoggedIn', 'deviceToken', 'preventLogout']),
        ...mapState('profileManagement', ['profileManagement']),
        ...mapState('magnifier', ['magnifier']),
        ...mapState('util', ['keyboard', 'bonusFunctions', 'browserSupported', 'windowWidth']),
        ...mapState('blackboard', ['blackboard']),
        ...mapState('badges', ['sickNoteBadge', 'blackboardBadge', 'timetableBadge', 'chatBadge', 'fileWidgetBadge', 'appBadge']),
        ...mapState('utilUnpersistent', ['blockMobileWidgetChangeByDrag','hideNavbar']),
        ...mapGetters('support',['supportPageOpen']),
        ...mapGetters('videoTutorial', ['videoTutorialsByFilename']),
        ...mapGetters('appointments', ['appointments']),
        ...mapGetters('auth', ['accountId']),
        ...mapGetters('deactivatedFeatures',['deactivatedFeatures', 'appsDeactivated','chatDeactivated','brettDeactivated','planDeactivated',
                                                'messagesDeactivated','filesDeactivated','calendarDeactivated','diaryDeactivated','pinboardDeactivated',]),
                                                
        allWidgetsMounted(){
            return this.chatMounted && this.brettMounted && this.planMounted && this.appwidgetMounted && this.filesystemMounted && this.messagesMounted;
        },
        page0WidgetsMounted(){
            if(this.windowWidth > 900 && this.page === 0 ){
                return   this.chatMounted && this.brettMounted && this.planMounted;
            }
            return true;
        },
        page1WidgetsMounted(){
            if(this.windowWidth > 900 && this.page === 1 ){
                return this.filesystemMounted && this.appwidgetMounted && this.messagesMounted;
            }
            return true;
        },
        isUpdatesOnOtherPage() {
            if (this.isSecondPageUpdated.length && this.page === 0) {
                return true;
            }
            if (this.isFirstPageUpdated.length && this.page === 1) {
                return true;
            }
            return false;
        },
        showNextSlide(){
            return !(this.messagesDeactivated && this.filesDeactivated && this.appsDeactivated)
        },
        btmNavItems(){
            const items = [];
        
            if(!this.planDeactivated){
                items.push(
                { name: 'stundenplan', alt: 'Stundenplan', icon: stundenplanIcon, goToIndex: items.length, bgColor: '#6995CD', color: 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);' },
                )
           }
            if(!this.brettDeactivated){
                items.push(
                { name: 'brett', alt: 'Schwarzes Brett', icon: brettIcon, goToIndex: items.length, bgColor: 'var(--v-dunkelgrau-base)', color: 'filter: brightness(0) saturate(100%) invert(40%) sepia(22%) saturate(0%) hue-rotate(171deg) brightness(102%) contrast(80%);' },

                )
            }
            if(!this.chatDeactivated){
                items.push(
                    { name: 'chat', alt: 'Chat', icon: chatIcon, goToIndex: items.length, bgColor: 'var(--v-chatColor-base)', color: 'filter: brightness(0) saturate(100%) invert(55%) sepia(8%) saturate(4130%) hue-rotate(70deg) brightness(97%) contrast(81%);' },

                )
            }
            if(!this.filesDeactivated){
                items.push(
                    { name: 'dateien', alt: 'Dateien', icon: dateienIcon, goToIndex: items.length, bgColor: 'var(--v-fileArchiveOrange-base)', color: 'filter: brightness(0) saturate(100%) invert(54%) sepia(78%) saturate(2780%) hue-rotate(337deg) brightness(99%) contrast(105%);' },
                )
            }
            if(!this.messagesDeactivated){
                items.push(
                { name: 'brief', alt: 'Elternbrief', icon: briefIcon, goToIndex: items.length, bgColor: '#94519B', color: 'filter: brightness(0) saturate(100%) invert(34%) sepia(43%) saturate(774%) hue-rotate(247deg) brightness(98%) contrast(81%)' },
                )
            }
            if(!this.appsDeactivated){
                items.push(
                { name: 'apps', alt: 'Apps', mdi: 'mdi-apps', goToIndex: items.length, bgColor: 'var(--v-headerblue-base)',}

                )
            }
            return items;
        },
        chatWidgetSlide(){
            return this.btmNavItems.findIndex((item)=> item.name === 'chat');
        }
    },
    watch: {
        allWidgetsMounted(newVal, oldVal){
            if (oldVal === false && newVal === true) {
                this.hidePreloader = true;
            }
        },
        page0WidgetsMounted(newVal, oldVal){
            if (oldVal === false && newVal === true) {
                this.hidePreloader = true;
            }
        },
        page1WidgetsMounted(newVal, oldVal){
            if (oldVal === false && newVal === true) {
                this.hidePreloader = true;
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('orientationchange', this.onOrientationChange);
    },
    async created() {
        this.checkQueryForPage();
        this.checkTutorialState();
        // Check query param for page
        const page = Number.parseInt(this.$route.query.page);
        if (page && Number.isSafeInteger(page)) {
            if (this.windowWidth > 900) {
                this.page = page;
                if (page > 1) {
                    this.page = Math.floor(page / 3);
                    await this.$router
                        .replace({ query: { ...this.$route.query, page: this.page }});
                }
            } else {
                this.currSlide = page;
            }
        }
    },
    async mounted() {
        setTimeout(() => {
            this.hidePreloader = true;
        }, 5000);
        this.crtAccount = await this.getCurrentAccount(true);
        window.addEventListener('orientationchange', this.onOrientationChange);
        this.usageAgreementNotSigned = this.crtAccount.signedUsageAgreement.isSigned ? false : true;
        this.requestPerson();
        this.requestBonusFunctions();
        this.checkSecurityQuestion();
        this.checkForCalendarUpdates();
        this.videoTutorial = (this.$route.query.page == 1)
            ? this.videoTutorialsByFilename['Tutorial_Lehrer2.mp4']
            : this.videoTutorialsByFilename['Tutorial_Lehrer.mp4'];
        // this.checkForPinboardUpdates();
        EventBus.$on('blackboard-badge', () => {
            this.isFirstPageUpdated.push('brett');
        });
        EventBus.$on('parental-badge', () => {
            this.isSecondPageUpdated.push('parental');
        });
        EventBus.$off('account-forcelogout');
        EventBus.$on('account-forcelogout', () => {
            if (!this.preventLogout) {
                this.logoutUser();
                this.$router.push({ name: 'login'});
            } else {
                this.setPreventLogout(false);
            }
        });
    
    //this is needed because if feature is disabled it wont mount so we would get stuck in infinite loading screen
        if(this.deactivatedFeatures.apps){
            this.appwidgetMounted = true;
        }
        if(this.deactivatedFeatures.plan){
            this.planMounted = true;
        }
        if(this.deactivatedFeatures.chat){
            this.chatMounted = true;
        }
        if(this.deactivatedFeatures.messages){
            this.messagesMounted = true;
        }if(this.deactivatedFeatures.blackboard){
            this.brettMounted = true;
        }
         const connectionsRes =  await backend.checkWebsocketConnections(this.crtAccount._id);
        const clientsObject = await connectionsRes.json();
        this.otherConnectedDevices = clientsObject.connected - 1;
        if(this.otherConnectedDevices > 0){
            this.showForceLogoutDialog = true;
        }
        await this.getLateTimes();
    },
    methods: {
        ...mapActions('lateTimes', ['getLateTimes']),
        ...mapActions('teachers', ['getMeTeacher', 'getProfilePicture', 'getMyTeacherGroups']),
        ...mapActions('util', ['requestBonusFunctions', 'toggleKeyboard']),
        ...mapActions('auth', ['logoutUser']),
        ...mapActions('faq', ['toggleFaq', 'closeFaq']),
        ...mapActions('weeklyView', ['toggleWeeklyView', 'closeWeeklyView']),
        ...mapActions('profileManagement', ['toggleProfileManagement', 'closeProfileManagement','openProfileManagement',]),
        ...mapActions('magnifier', ['toggleMagnifier', 'turnOffMagnifier']),
        ...mapActions('sickNote', [ 'getSickNotes' ]),
        ...mapActions('auth', ['getCurrentAccount']),
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapMutations('support', ['setSupportPageOpen']),
        ...mapActions("calendar", ['getUnseenEventsForUser']),
        ...mapMutations('profileManagement', ['setProfileManagement']),
        ...mapMutations('auth', ['setPreventLogout']),
        ...mapActions('pinboard', ['getPinboards', 'hasUserSeenPinboard', 'toggleOpenPinboardLibrary']),
        confirmForceLogout(){
            this.showForceLogoutDialog = false;
            this.showSnackbar({message:`Andere Geräte erfolgreich ausgeloggt.`, color:'success'})
        },
        isMounted(item){
            switch(item.name){
                case 'stundenplan':
                    return this.planMounted;
                case 'brett':
                    return this.brettMounted;
                case 'chat':
                    return this.chatMounted;
                case 'apps':
                    return this.appwidgetMounted;
                case 'brief':
                    return this.messagesMounted;
                case 'dateien':
                    return this.filesystemMounted;
                default: 
                return true;
            }
        },
        revokeUsageAgreement(){
            this.usageAgreementNotSigned = true;
            this.revokeAgreement = true;
        },
        async checkForCalendarUpdates() {
            const eventsNotSeen = await this.getUnseenEventsForUser();
            this.eventsNotSeen = eventsNotSeen.length;

            this.eventsNotSeen.length > 0 ? this.isSecondPageUpdated.push('calendar') : this.isSecondPageUpdated;
            this.showCalendarBadge = this.eventsNotSeen > 0;
        },
        async checkForPinboardUpdates() {
            const pinboardsJson = await this.getPinboards();
            for(var i = 0; i < pinboardsJson.length; i++) {
                const crtPinboard = pinboardsJson[i];
                const resJson = await this.hasUserSeenPinboard( { pinboardId: crtPinboard._id, accountId: this.crtAccount._id } );
                if(!resJson.seen) {
                    this.isSecondPageUpdated.push('pinboard');
                    return;
                }
            }
        },
        async changePage(direction) {
            this.page = Math.abs((this.page + direction) %2);
            // await this.setFocusOnNextPage()

            // update query params
            await this.$router.replace({ query: { ...this.$route.query, page: this.page } });
            // allow tutorial to be seen again
            this.resetTutorial();
            this.videoTutorial = (this.$route.query.page == 1)
                ? this.videoTutorialsByFilename['Tutorial_Lehrer2.mp4']
                : this.videoTutorialsByFilename['Tutorial_Lehrer.mp4'];
            this.videoTutorialClosed = false;
        },

        closeIntroTutorial() {
            this.introTutorial = true;
            localStorage.setItem('tutorial', 'true');
        },

        closeBBBTutorial() {
            this.showBBBTutorial = false;
            localStorage.setItem('bbbTut', 'true');
            this.openBBB();
        },

        openBBB() {
            this.callbackMethod();
        },

        vueSlickCarouselAfterChange() {
            this.slideChangeAllowed = true;
            this.currSlide = this.$refs.carousel.$refs.innerSlider.currentSlide;

            if (this.currSlide !== 0) {
                this.closeWeeklyView();
            }

            // add page to query --> this is redundant and therefore just spams the console
            // this.$router.replace({ query: { ...this.$route.query, page: this.currSlide } });
        },

        /*async subscribe() {
            navigator.serviceWorker.getRegistrations().then(async (registrations) => {
                // Register Push
                const subscription = await registrations[0].pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: this.urlBase64ToUint8Array('BKFBvlmQYh9nNP9ECrMgMj-3v__wDW8Q3M0NPcHpH3LXSq7YsYvSR_dj757TxzwL2hKr--gN6SuIyuy3H0OUgeo')
                });
                await backend.subscribe({
                    subscription,
                    deviceToken: this.deviceToken,
                });
            });
            this.showSnackbar({
                message: 'Benachrichtigungen aktiv',
                color: 'info'
            })
        },*/
        startBBBTut(callback) {
            this.callbackMethod = callback;
            if(this.bbbTutorialCookie) {
                this.callbackMethod();
            } else {
                this.showBBBTutorial = true;
            }
        },

        checkSecurityQuestion() {
            if (!this.securityQuestionSet) {
                this.securityQuestionDialog = true;
                // TODO: also get available security questions
                this.securityQuestions = ['Wie heißt deine Oma mit Vornamen?', 'Wie heißt dein Opa mit Vornamen?'];
            }
        },

        // Clear fields and close dialog if request to backend was successful
        setSecurityQuestion() {
            this.securityQuestion = null;
            this.securityQuestionAnswer = '';
            this.securityQuestionDialog = false;
        },

        resetTutorial() {
            this.introTutorial = false;
            localStorage.setItem('tutorial', 'false');
        },

        checkTutorialState() {
            this.introTutorial = JSON.parse(localStorage.getItem('tutorial'));
            this.bbbTutorialCookie = JSON.parse(localStorage.getItem('bbbTutorial'));
        },

        async requestPerson() {
            this.person = await this.getMeTeacher();
            this.showEmailBadge = this.person.hideEmailBadge ? !this.person.hideEmailBadge : true;
            const hello = await this.getMyTeacherGroups();
        },

        reloadPage() {
            this.$router.go();
        },

        //#region btmNav methods
        switchSlide(index) {
            if (this.slideChangeAllowed && index !== this.currSlide) {
                if (this.babyView) {
                    this.$refs.carouselBabyView.goTo(index);
                } else {
                    this.$refs.carousel.goTo(index);
                }
                this.currSlide = index;
                //this.slideChangeAllowed = false;
                // set weeklyView to closed on change
                this.sidebar = false; 
                this.sidebarMobile = false;
                this.closeWeeklyView();

                // add page to query
                this.$router.replace({ query: { ...this.$route.query, page: this.currSlide } });
            }
            else if(index === this.currSlide){
                this.reloadWidget(index)
            }
        },
        reloadWidget(index){
            switch(index){
                  case 0:
                    this.reloadPlan =true;
                    break;
                case 1:
                     this.reloadBrett = true;
                     break;
                case 2:
                    this.reloadChat = true;
                    break;
                case 3:
                   this.reloadFilesystem = true;
                    break;
                case 4:
                   this.reloadMessages = true;
                    break;
                case 5:
                     this.reloadAppwidget = true;
                    break;
            }
        },
        onOrientationChange() {
            this.switchSlide(0);
            this.slideChangeAllowed = true;
        },

        /**
         * Returns the value/text to display in the badge for given widget index
         * 0 = timetable, 1 = blackboard, 2 = chat, 3 = file widget, 4 = sick note, 5 = app
         * @param index of the widget you want the badge for
         */
        getBadge(index) {
            switch (index) {
                case 0:
                    return this.timetableBadge;
                case 1:
                    return this.blackboardBadge;
                case 2:
                    return this.chatBadge;
                case 3:
                    return this.fileWidgetBadge;
                case 4:
                    return this.sickNoteBadge;
                case 5:
                    return this.appBadge;
                default:
                    return false;
            }
        },
        //#endregion

        openDiary() {
            this.diaryDialog = true;
        },

        openCalendar() {
            this.showCalendarWidget = true;
        },

        async hasUserNewEvents() {
            this.showCalendarBadge = false;
            const eventsNotSeen = await this.getUnseenEventsForUser();
            this.eventsNotSeen = eventsNotSeen.length;

            if (this.eventsNotSeen > 0) {
                this.showCalendarBadge = true;
            }
        },

        handleAudioUrl(audioUrl) {
            this.audioOverlay = true;
            this.audioUrl = audioUrl;
            setTimeout(() => {
                const audioPlayer = document.getElementById('audioPlayer');
                audioPlayer.play();
            }, 500);

        },

        checkQueryForPage() {
            if (this.$route.query.page) {
                this.initialSlide = parseInt(this.$route.query.page);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.backgroundImg {
    background-image: url(../../assets/Bilder/scribble.svg);
    background-repeat: repeat;
    overflow: hidden;
    height: 100%;
}

table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 10px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}
table,
tr,
td {
    border: none;
}

.small {
    display: none;
}

.big {
    display: flex;
}

.ellipsis {
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.greyText {
    color: var(--v-dunkelgrau-base);
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
        hue-rotate(36deg) brightness(104%) contrast(105%);
}

.iconToHeaderblue {
    filter: brightness(0) saturate(100%) invert(21%) sepia(43%) saturate(391%) hue-rotate(183deg) brightness(94%) contrast(92%);
}

#custom-disabled.v-btn--disabled {
    background-color: var(--v-fgrau-base) !important;
    color: white;
}

.roundProfilePic {
    border-radius: 50%;
    height: 35px;
    width: 35px;
}

.roundProfilePicBtmNav {
    border-radius: 50%;
    height: 25px;
    width: 25px;
}

.btmNav {
    display: none;
    z-index: 11;
    position: fixed;
    bottom: 0;
    width: 100% !important;
    height: 70px !important;
    background-color: var(--v-headerblue-base);
}

.btmNavBtn {
    height: 50px !important;
    width: 50px !important;
    background-color: white !important;
    border-radius: 8px !important;
}

.hiddenBtmNavBtn {
    height: 50px !important;
    width: 50px !important;
    background-color: transparent !important;
    border-radius: 8px !important;
}

/*removes darker background after btn is clicked
    also works without the specific class in front*/
.btmNavBtn.v-btn:before {
    opacity: 0 !important;
}
.arrowBtn:before {
    opacity: 0 !important;
}

.btmNavIcon {
    height: 35px;
}

.hideMe {
    display: none !important;
}

.pointer {
    cursor: pointer;
}

@media only screen and (max-width: 900px) {
    .small {
        display: block !important;
    }

    .big {
        display: none !important;
    }

    .topPadding {
        padding-top: 12px;
    }

    .displayHide {
        display: none;
    }

    .btmNav {
        display: block;
    }

    .reload {
        display: block;
        position: fixed;
        bottom: 0;
        width: 100% !important;
        margin-bottom: 78px !important;
    }
}

.slick-slider {
    user-select: auto !important;
}

.animateIcon {
    overflow: hidden;
}

.animateIcon:after {
    animation: shine 60s ease-in-out infinite;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0);
    background: linear-gradient(
        to right,
        rgba(112, 112, 112, 0.2) 0%,
        rgba(112, 112, 112, 0.2) 77%,
        rgba(112, 112, 112, 0.5) 92%,
        rgba(112, 112, 112, 0) 100%
    );
}

/* Hover state - trigger effect */

/* Active state */
.animateIcon:active:after {
    opacity: 0;
}

@keyframes shine {
    1% {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    5% {
        opacity: 0;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
    }
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}

.imgFocus {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
        hue-rotate(36deg) brightness(104%) contrast(105%) !important;
}

/*    .buttonFocusHover:hover {
      background-color: #6994cd !important;
    }*/

.buttonFocusHover:hover .imgFocusHover {
    filter: invert(51%) sepia(66%) saturate(310%) hue-rotate(175deg) brightness(98%) contrast(91%) !important;
}
</style>
