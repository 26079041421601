import { render, staticRenderFns } from "./AppWidget.vue?vue&type=template&id=e4bb19b4&scoped=true&"
import script from "./AppWidget.vue?vue&type=script&lang=js&"
export * from "./AppWidget.vue?vue&type=script&lang=js&"
import style0 from "./AppWidget.vue?vue&type=style&index=0&id=e4bb19b4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4bb19b4",
  null
  
)

export default component.exports